export default {
  strings: {
    search: 'SEARCH',
  },
  notifications: {
    noEmptyFields: {
      error: 'Please, provide a term to search. ',
    },
  },
};

import React, { useEffect, useState, Fragment } from 'react';
import config from './config';
import { helpers } from './helpers';
import './styles.scss';

interface CollapsibleProps {
  item: any;
  collapsibleState: any;
}

const Collapsible: React.FC<CollapsibleProps> = ({
  item,
  collapsibleState,
}) => {
  const [ownState, setOwnState] = useState({
    [item?.device_id]: collapsibleState,
  });

  const [settingsWithoutConf, setSettingsWithoutConf] = useState<any>({});
  const [configs, setConfigs] = useState<any>({});
  const [cdvs, setCdvs] = useState<any>([]);

  const toggleAcc = (itemId: any) => {
    setOwnState((prevState: any) => {
      return {
        ...prevState,
        [itemId]: { open: !ownState[itemId]?.open },
      };
    });
  };

  useEffect(() => {
    if (item && item?.details && item?.details?.settings) {
      if (
        item?.details?.settings &&
        item?.details?.settings?.conf &&
        item?.details?.settings?.conf?.cdvs
      ) {
        setCdvs(item?.details?.settings?.conf?.cdvs);
      }

      setConfigs(helpers.filterConfigs(item?.details?.settings) || {});

      setSettingsWithoutConf(
        helpers.filterSettings(item?.details?.settings) || {},
      );
    }
  }, [item]);

  return (
    <div
      id={`accordion-${item?.device_id}`}
      className="dev-info-results-collapsible"
    >
      <div className="card">
        <div className="card-header" id={item?.device_id}>
          <h5 className="mb-0">
            <button
              className="btn btn-link"
              data-toggle="collapse"
              data-target={`#col-${item?.device_id}`}
              aria-expanded={
                ownState &&
                ownState[item?.device_id] &&
                ownState[item?.device_id]?.open
              }
              aria-controls={`col-${item?.device_id}`}
              onClick={() => {
                toggleAcc(item?.device_id);
              }}
            >
              <span className="more-info-res-detls lead">
                More information about{' '}
                {item?.device_name
                  ? item?.device_name
                  : item?.name
                  ? item?.name
                  : 'device'}
              </span>
            </button>
          </h5>
        </div>

        <div
          id={`col-${item?.device_id}`}
          className={
            ownState &&
            ownState[item?.device_id] &&
            ownState[item?.device_id]?.open &&
            ownState[item?.device_id].open
              ? 'collapse show'
              : 'collapse'
          }
          aria-labelledby={item?.device_id}
          data-parent={`#accordion-${item?.device_id}`}
        >
          <div className="card-body">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 pdg">
                {configs &&
                  Object.entries(configs).map(
                    ([key, value]: [any, any], i: number) => {
                      return key === 'cdvs' ? null : (
                        <div className="row item-padding" key={i}>
                          {/* <div className="col-9 pdg-l"> */}
                          <div className="col-7 pdg-l">
                            <span className="lead sm-lead bolder-ft">
                              {config[key as keyof typeof config] || key}
                            </span>
                          </div>
                          {/* <div className="col-3 pdg-l right-align-text pdg-r"> */}
                          <div className="col-5 pdg-l right-align-text pdg-r">
                            {helpers.handleValueToDisplay(value)}
                          </div>
                        </div>
                      );
                    },
                  )}
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 pdg">
                {settingsWithoutConf &&
                  Object.entries(settingsWithoutConf).map(
                    ([key, value]: [any, any], i: number) => {
                      return key === 'cdvs' ? null : (
                        <div className="row item-padding" key={i}>
                          {/* <div className="col-9 pdg-r"> */}
                          <div className="col-7 pdg-r">
                            <span className="lead sm-lead  bolder-ft">
                              {config[key as keyof typeof config] || key}
                            </span>
                          </div>
                          {/* <div className="col-3 pdg-r right-align-text pdg-r"> */}
                          <div className="col-5 pdg-r right-align-text pdg-r">
                            <span className="lead sm-lead">
                              {helpers.handleValueToDisplay(value)}
                            </span>
                          </div>
                        </div>
                      );
                    },
                  )}
              </div>
              <div className="row mlmr0 pdg">
                {cdvs && cdvs.length === 0 ? (
                  <></>
                ) : cdvs && cdvs.length === 1 ? (
                  <div className="acc-container1 pdg">
                    <h4 className="label-connected-devs">Connected devices</h4>

                    <div className="container pdg item-padding">
                      <div className="row">
                        {Object.entries(cdvs).map(
                          ([key, val]: [any, any], index: number) => {
                            return (
                              <Fragment key={index}>
                                <div className="col-12 pdg pb-1r">
                                  <span className="lead dev-presentation">
                                    {val?.cdid ? val?.cdid : 'Device'}
                                  </span>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-6 pdg">
                                  {Object.entries(val)
                                    .slice(
                                      0,
                                      Math.round(Object.entries(val).length) /
                                        2,
                                    )
                                    .map(
                                      ([key, val]: [any, any], i: number) => {
                                        return (
                                          <div
                                            className="container pdg"
                                            key={i}
                                          >
                                            <div className="row">
                                              <div className="col-xs-12 col-sm-12 col-md-9 pdg item-padding">
                                                <span className="lead sm-lead bolder-ft">
                                                  {config[
                                                    key as keyof typeof config
                                                  ] || key}
                                                </span>
                                              </div>
                                              <div className="col-xs-12 col-sm-12 col-md-3 pdg item-padding">
                                                <span className="lead sm-lead">
                                                  {!val
                                                    ? 'No value to display'
                                                    : typeof val === 'string' ||
                                                      typeof val === 'number'
                                                    ? val
                                                    : typeof val === 'boolean'
                                                    ? val.toString()
                                                    : Array.isArray(val)
                                                    ? null
                                                    : helpers.isOb(val)
                                                    ? null
                                                    : ''}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      },
                                    )}
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 pdg">
                                  {Object.entries(val)
                                    .slice(
                                      Math.round(Object.entries(val).length) /
                                        2,
                                    )
                                    .map(
                                      ([key, val]: [any, any], i: number) => {
                                        return (
                                          <div
                                            className="container pdg"
                                            key={i}
                                          >
                                            <div className="row">
                                              <div className="col-xs-12 col-sm-12 col-md-9 pdg item-padding">
                                                <span className="lead sm-lead bolder-ft">
                                                  {config[
                                                    key as keyof typeof config
                                                  ] || key}
                                                </span>
                                              </div>
                                              <div className="col-xs-12 col-sm-12 col-md-3 pdg item-padding">
                                                <span className="lead sm-lead">
                                                  {!val
                                                    ? 'No value to display'
                                                    : typeof val === 'string' ||
                                                      typeof val === 'number'
                                                    ? val
                                                    : typeof val === 'boolean'
                                                    ? val.toString()
                                                    : Array.isArray(val)
                                                    ? null
                                                    : helpers.isOb(val)
                                                    ? null
                                                    : ''}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      },
                                    )}
                                </div>
                              </Fragment>
                            );
                          },
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="acc-container1 pdg">
                    <h4 className="label-connected-devs">Connected devices</h4>
                    <div id={`accordion-details-cdvs`} className="pdg">
                      {cdvs &&
                        cdvs?.map((cdv: any, index: number) => {
                          return (
                            <div
                              className="card internal-collapsible-item"
                              key={index}
                            >
                              <div
                                className="card-header"
                                id={`id-collapse-${helpers.getTheID(
                                  cdv,
                                  index,
                                )}`}
                              >
                                <h5 className="mb-0">
                                  <button
                                    className="btn btn-link"
                                    data-toggle="collapse"
                                    data-target={`#collapse-${helpers.getTheID(
                                      cdv,
                                      index,
                                    )}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse-${helpers.getTheID(
                                      cdv,
                                      index,
                                    )}`}
                                  >
                                    <span className="more-info-res-detls lead">
                                      {cdv?.cdid ? cdv?.cdid : 'Device'}
                                    </span>
                                  </button>
                                </h5>
                              </div>

                              <div
                                id={`collapse-${helpers.getTheID(cdv, index)}`}
                                className="collapse"
                                aria-labelledby={`id-collapse-${
                                  cdv?.cdid
                                    ? cdv?.cdid
                                    : cdv?.dmac
                                    ? cdv?.dmac
                                    : index
                                }`}
                                data-parent={`#accordion-details-cdvs`}
                              >
                                <div className="card-body">
                                  {Object.entries(cdv).map(
                                    ([key, val]: [any, any], i: number) => {
                                      return (
                                        <div
                                          className="container pdg item-padding"
                                          key={i}
                                        >
                                          <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-6 pdg">
                                              <span className="lead sm-lead bolder-ft">
                                                {config[
                                                  key as keyof typeof config
                                                ] || key}
                                              </span>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-6 pdg">
                                              <span className="lead sm-lead">
                                                {!val
                                                  ? 'No value to display'
                                                  : typeof val === 'string' ||
                                                    typeof val === 'number'
                                                  ? val
                                                  : typeof val === 'boolean'
                                                  ? val.toString()
                                                  : Array.isArray(val)
                                                  ? null
                                                  : helpers.isOb(val)
                                                  ? null
                                                  : ''}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    },
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collapsible;

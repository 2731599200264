import React, { useState, Fragment } from 'react';
import moment from 'moment';
import { ListGroupProps } from './interface';
import { FaRegCopy } from 'react-icons/fa';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { Notification } from 'business/modules/admin/common';
import './styles.scss';

const ListGroup: React.FC<ListGroupProps> = ({ returnValue, item }) => {
  const formattedTimeStr = `MM-DD-YYYY, hh:mm A`;

  const [tokenIsHidden, setTokenIsHidden] = useState<boolean>(true);

  const labelStrings: any = {
    createdAt: 'Created at',
    client_id: 'Client ID',
    email: 'E-mail',
    company: 'Company',
    details: 'Profile Details',
    status: 'Status',
    client_user: 'Client User',
    client_secret: 'Client Secret',
    first_name: 'First name',
    last_name: 'Last name',
    address: 'Address',
    phone: 'Phone',
    state: 'State',
    city: 'City',
    zip_code: 'Zipcode',
  };

  const formatDate = (date: any) => {
    return date ? moment(new Date(date)).format(formattedTimeStr) : '';
  };

  const handleValueDisplay = (key: string, value: any) => {
    return key === 'createdAt' || key === 'updatedAt'
      ? formatDate(value)
      : returnValue(value, key);
  };

  const toggleTokenDisplay = () => {
    setTokenIsHidden(!tokenIsHidden);
  };

  const handleLabelString = (str: string) => {
    return labelStrings[str as keyof typeof labelStrings] || '';
  };

  const copyContent = async (copyTxtToClip: any) => {
    try {
      await navigator.clipboard.writeText(copyTxtToClip);
    } catch (err) {
      //handle error copying to clipboard
    }
  };

  const copyToClipboard = (item: any, id: string) => {
    copyContent(item);

    Notification({
      title: 'Success',
      message: `${labels[id]} copied to clipboard.`,
      type: 'success',
    });
  };

  const labels: any = {
    client_user: `Client User`,
    client_secret: `Client Secret`,
  };

  return (
    <div className="div-informations">
      <ul className="list-group" id="view-dev--user-dtls">
        {Object.keys(item).length > 0 &&
          Object.entries<any>(item).map(([key, value], idx) => {
            return (
              <div className="container pdg break-w" key={idx}>
                <div className="row pdg contents">
                  <span
                    className={`lead lead--smaller ftw600 ${
                      key === 'details' ? 'spec-class-details-header' : ''
                    }`}
                  >
                    {handleLabelString(key)}
                  </span>

                  {key === 'client_secret' ? (
                    <>
                      <button
                        className="btn btn-light btn-block toggle-token"
                        onClick={toggleTokenDisplay}
                      >
                        {tokenIsHidden ? (
                          <>
                            <AiOutlineEye
                              style={{
                                marginBottom: '0.2rem',
                                marginRight: '4px',
                              }}
                            />
                            Show{' '}
                          </>
                        ) : (
                          <>
                            <AiOutlineEyeInvisible
                              style={{
                                marginBottom: '0.1rem',
                                marginRight: '4px',
                              }}
                            />
                            Hide{' '}
                          </>
                        )}
                      </button>
                      {!tokenIsHidden && (
                        <div className="form-control input-alike-token">
                          <div className="container pdg">
                            <div className="row">
                              <div className="col pdg">
                                <span className="lead span-key-040">
                                  {handleValueDisplay(key, value)}
                                </span>
                              </div>

                              <div className="col-auto pdg">
                                <button type="button">
                                  <FaRegCopy
                                    style={{
                                      color: '#000000',
                                      strokeWidth: '30',
                                      stroke: 'white',
                                    }}
                                    className="copy-to-clipboard-icon-clt"
                                    onClick={() => copyToClipboard(value, key)}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : key === 'details' ? (
                    <>
                      {Object.entries<any>(value).map(([key, value], idx) => {
                        return (
                          <Fragment key={idx}>
                            <span className="lead lead--smaller ftw600">
                              {handleLabelString(key)}
                            </span>
                            <div className="form-control input-alike">
                              <span className="lead span-key-040">
                                {handleValueDisplay(key, value)}
                              </span>
                            </div>
                          </Fragment>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {key === 'client_user' ? (
                        <div className="form-control input-alike-token">
                          <div className="container pdg">
                            <div className="row">
                              <div className="col pdg">
                                <span className="lead span-key-040">
                                  {handleValueDisplay(key, value)}
                                </span>
                              </div>
                              <div className="col-auto pdg">
                                <button type="button">
                                  <FaRegCopy
                                    style={{
                                      color: '#000000',
                                      strokeWidth: '30',
                                      stroke: 'white',
                                    }}
                                    className="copy-to-clipboard-icon-clt"
                                    onClick={() => copyToClipboard(value, key)}
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="form-control input-alike">
                          {' '}
                          <span className="lead span-key-040">
                            {handleValueDisplay(key, value)}
                          </span>{' '}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
      </ul>
    </div>
  );
};
export default ListGroup;

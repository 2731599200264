import React from 'react';
import './styles.scss';

interface RowProps {
  outClass: string;
  firstClass: string;
  firstContent: any;
  secondClass: string;
  secondContent: any;
  optionalContent?: any;
}

{
  /* <Row

outClass={}
firstClass={}
firstContent={}
secondClass={}
secondContent={}



/> */
}

const Row: React.FC<RowProps> = ({
  outClass,
  firstClass,
  firstContent,
  secondClass,
  secondContent,
  optionalContent,
}) => {
  return (
    <div className={outClass}>
      <span className={firstClass}>{firstContent}</span>
      <span className={secondClass}>{secondContent}</span>

      {optionalContent}
      <div className="row"></div>
    </div>
  );
};

export default Row;

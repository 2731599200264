import React from 'react';
import AdminProfileDropdown from '../AdminProfileDropdown';
import Search from '../Search';
import './styles.scss';

interface HeaderProps {
  open?: any;
}

const Header: React.FC<HeaderProps> = ({ open }) => {
  return (
    <div className="header">
      <div className="navbar-collapse" id="navbarSupportedContent-admin">
        <ul className="navbar-nav float-right d-flex flex-row align-items-center">
          <div className="navbar-nav--wrapper">
            <Search />
          </div>
          <AdminProfileDropdown />
        </ul>
      </div>
    </div>
  );
};

export default Header;

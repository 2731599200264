import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import validate from 'validate.js';
import { Input, Button } from '../../../../../../../react-components';
import { useAuth } from '../../../../../sessions/hooks/auth';
import { config } from './config';
import './styles.scss';

const SignInAdmin: React.FC = () => {
  const { signInAdmin } = useAuth();
  const [fieldsState, setFieldsState] = useState(config.fieldsState);
  const [errors, setErrors]: any = useState(true);
  const [loading, setLoading]: any = useState(false);

  async function handleSubmit(event: any) {
    event.preventDefault();
    setErrors(false);
    setLoading(true);
    let isValid = true;

    await validate.async(fieldsState, config.constraints).then(null, err => {
      setErrors(err);
      isValid = false;
      setLoading(false);
    });
    if (!isValid) return;
    setLoading(false);
    await signInAdmin(fieldsState);
  }

  return (
    <div className="row">
      <div id="admin-login-account-form" className="main-wrapper">
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center position-relative">
          <div className="auth-box row">
            <div className="col-lg-12 col-md-12 bg-white">
              <div className="session-form-body">
                <Helmet>
                  <title>{config.strings.title} - AirCycler</title>
                </Helmet>

                <div>
                  <h2 className="mt-1 mb-3 text-center">
                    {config.strings.title}
                  </h2>
                  <p className=" pb-3 text-center ">
                    Enter your Aircycler admin account
                  </p>
                </div>

                <div className="row">
                  {Object.keys(config.fields).map((name, index) => (
                    <Input
                      handleChange={(value: any) =>
                        setFieldsState((prevState: any) => ({
                          ...prevState,
                          [name]: value,
                        }))
                      }
                      handleChangeError={(err: any) =>
                        setErrors((prevState: any) => ({
                          ...prevState,
                          [name]: err,
                        }))
                      }
                      type={config.fields[name].type}
                      cols={config.fields[name].cols}
                      label={config.fields[name].label}
                      name={name}
                      maximum={config.fields[name].maximum}
                      value={fieldsState[name]}
                      error={errors[name]}
                      autoFocus={config.fields[name].autofocus}
                      constraint={config.constraints[name]}
                      key={index}
                    />
                  ))}
                </div>

                <div className="row d-flex justify-content-center pt-4">
                  <Button
                    variant="primary"
                    className="btn  btn-primary btn-block"
                    type="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {config.strings.confirm}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInAdmin;

import React, { useState, useEffect, Fragment } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { InstalledDevicesProps } from './interface';
import { Template, List, Modal } from 'business/modules/admin/common';
import { useHistory } from 'react-router-dom';
import { IoAdd } from 'react-icons/io5';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { MdDelete } from 'react-icons/md';
import { RxMagnifyingGlass } from 'react-icons/rx';
import {
  ConnectedIcon,
  DisconnectedIcon,
} from '../../../../assets/device-icons';
import './styles.scss';

const InstalledDevices: React.FC<InstalledDevicesProps> = ({
  saveDeviceItemGlobally,
  handleDevsListCounterAndOrder,
  orders,
  installersDevCounter,
  installersDevIntendedCounter,
  orderedList,
  updateOrderState,
  deviceItem,
}) => {
  const history = useHistory();
  const [devices, setDevices]: any = useState([]);
  const [loadedDevices, setLoadedDevices] = useState<boolean>(false);
  const [initList, setInitList]: any = useState([]);
  const [current, setCurrent] = useState<string>('');

  const incremented: any = {
    name: installersDevIntendedCounter.name + 1,
    location: installersDevIntendedCounter.location + 1,
    status: installersDevIntendedCounter.status + 1,
  };

  //emulate API call while there is no data
  const getThisInstallerDevs = () => {
    let unordered: any = [];
    //will call the function to get installers list here

    //if response is OK:
    if (config.mockDevices.statusCode === 200) {
      setDevices(config.mockDevices.body.data);

      // this keeps the default order:

      let u = config.mockDevices.body.data.forEach((elem: any) => {
        unordered.push(elem);
      });

      setInitList(unordered);

      setLoadedDevices(true);
    } else {
      //handle error
    }
  };

  const deleteDevice = () => {};

  const addDevice = (item: any) => {
    history.push(
      '/admin/dashboard/companies-list/company/installers/devices/add-device',
    );
  };

  const editDevice = (item: any) => {
    history.push(
      '/admin/dashboard/companies-list/company/installers/devices/edit-device',
    );
  };

  const retrieveColValue = (item: any, position: string) => {
    switch (position) {
      case '1':
        return item.device_name;
      case '2':
        return (
          <>
            {' '}
            {item.city}, {item.state}
          </>
        );
      case '3':
        return item.status ? (
          <>
            {' '}
            <ConnectedIcon className="icon--1--status" /> active
          </>
        ) : (
          <>
            <DisconnectedIcon className="icon--1--status" /> inactive
          </>
        );

      default:
        return ``;
    }
  };

  useEffect(() => {
    getThisInstallerDevs();
  }, []);

  useEffect(() => {
    if (orders.orderByName) {
      setCurrent(`name`);
    } else if (orders.orderByLocation) {
      setCurrent(`location`);
    } else if (orders.orderByStatus) {
      setCurrent(`status`);
    } else if (orderedList && loadedDevices && initList.length > 0) {
      setCurrent(``);
    }
  }, [initList, orders, installersDevIntendedCounter, installersDevCounter]);

  useEffect(() => {
    return () => {
      handleDevsListCounterAndOrder(config.resettedCounter, 0);
    };
  }, []);

  const returnAccordionDetails = (item: any) => {
    return (
      <div className="jumbotron" id="accordion--details--installer">
        <h6 className="display-5"> {config.strings.main}</h6>
        <p className="lead smlead">
          {config.strings.lead}
          <span className="stronger-ft">{item?.device_name}</span>.
        </p>

        <div className="card">
          <div className="card-body">
            <h5 className="card-title ml--8">{config.strings.cardTitle}</h5>
            <h6 className="card-subtitle text-muted ml--8">
              <>
                <IoInformationCircleOutline className="the-icon" />{' '}
                <span className="card-subtitle--2">
                  {' '}
                  {config?.strings?.cardSubtitle}
                </span>
              </>
            </h6>
            <div className="card-text">
              <div className="container pdg">
                <div className="row mt--15">
                  {Object.entries<any>(item).map(([key, value], idx) => {
                    return (
                      functions.isToRenderKey(key) && (
                        <div
                          className="col-xs-12 col-sm-12 col-md-4 installer-details-acc"
                          key={idx}
                        >
                          <Fragment key={key}>
                            <span className="lead fts09">
                              {functions.returnLabel(key)}
                            </span>

                            <span className={`lead fts09 ${`break-word--all`}`}>
                              {key === `status`
                                ? functions.renderStatus(value)
                                : value}
                            </span>
                          </Fragment>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Template
      id="installer-devices-list"
      title={config.strings.title1}
      goBack={() => {
        history.push('/admin/dashboard/companies-list/company/installers');
      }}
    >
      <div className="installed--devs--list--section">
        <div className="container pdg">
          <div className="row pdg">
            <div className="col-xs-12 col-sm-12 col-md-10">
              <h6>{config.strings.title1}</h6>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-2 pdgl">
              <button className="btn btn--dev--inst" onClick={addDevice}>
                <IoAdd className="svg-inst1 mr--04" />
                <span className="btn-installer-label">
                  {config.strings.btn}
                </span>
              </button>
            </div>
          </div>
        </div>

        <List
          id="companies-list-l001-2"
          rowClasses={config.rowClasses}
          itemsPerPage={10}
          list={devices}
          loaded={loadedDevices}
          current={current}
          orders={orders}
          manageListOrder={handleDevsListCounterAndOrder}
          orderedList={orderedList}
          sectionCounter={installersDevIntendedCounter}
          sectionCount={installersDevCounter}
          updateOrderState={updateOrderState}
          incremented={incremented}
          unorderedDefault={initList}
          handles={{}}
          config={config}
          firstFun={(item: any) => {}}
          secondFun={editDevice}
          fourthFunction={() => {}}
          setItem={(item: any) => {
            saveDeviceItemGlobally(item);
          }}
          retrieveColValue={retrieveColValue}
          returnAccordionDetails={returnAccordionDetails}
          helperOrderData={[]}
        />
      </div>

      <Modal
        config={config}
        id={`delete-device-modal-id`}
        title={config.modal.delete.title}
        icon={<MdDelete className="edit" />}
        body={config?.modalStrings?.aboutToDel}
        onClick={deleteDevice}
        /*
          modalMustClose and handleModalState 
          will be used when there is an endpt 
          to delete the dev
        */
        // modalMustClose={modalMustClose}
        // handleModalState={handleModalState}
        modalMustClose={true}
        handleModalState={() => {}}
        label={config?.modalStrings?.labelOK}
        className={`small-button nok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={config?.modalStrings?.sureToProceed}
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
      />

      <Modal
        config={config}
        id={`view-device-modal-id`}
        title={config.modal.view.title}
        icon={<RxMagnifyingGlass className="edit" />}
        body={
          <>
            <div className="card" id="list-cnt-card-box1">
              <div className="card-body">
                <h5 className="card-title">{config.modal.view.sectionTitle}</h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {config.modal.view.sectionSubtitle}
                </h6>
                <div className="card-text">
                  <ul className="list-group">
                    {Object.keys(deviceItem).length > 0 &&
                      Object.entries<any>(deviceItem)
                        .filter(
                          ([key, value]) =>
                            config.relevantKeys.indexOf(key) > -1,
                        )
                        .map(([key, value], idx) => {
                          if (key === 'contact') return null;

                          return (
                            <div className="container pdg break-w" key={idx}>
                              <div className="row pdg contents">
                                <span className="lead lead--smaller ftw600">
                                  {functions.returnKey(key)}
                                </span>

                                <div className="form-control input-alike">
                                  {!functions.isContact(key) && (
                                    <span className="lead span-key-040">
                                      {functions.returnValue(value, key)}{' '}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                  </ul>
                </div>
              </div>
            </div>
          </>
        }
        onClick={() => {}}
        // modalMustClose={modalMustClose}
        // handleModalState={handleModalState}
        modalMustClose={true}
        handleModalState={() => {}}
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={``}
        hasConfirmBtn={false}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminInstallerDevsState } = state;
  return {
    orders: adminInstallerDevsState.orders,
    orderedList: adminInstallerDevsState.orderedList,
    installersDevCounter: adminInstallerDevsState.installersDevCounter,
    installersDevIntendedCounter:
      adminInstallerDevsState.installersDevIntendedCounter,
    deviceItem: adminInstallerDevsState.deviceItem,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminInstallerDevsState } = dispatch;
  return {
    updateOrderState: adminInstallerDevsState.updateOrderState,
    saveDeviceItemGlobally: adminInstallerDevsState.saveDeviceItemGlobally,
    handleDevsListCounterAndOrder:
      adminInstallerDevsState.handleDevsListCounterAndOrder,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InstalledDevices);

import { ConnectionBroker } from '../../core';
import { functions } from '../helpers';

export const searchState = {
  state: {
    searchTermInput: '',
    resultsAreLoaded: false,
    searchResults: [],
    resultsLoaded: false,
    errorOnResults: false,
    currentResult: {},
    orderedCurrentResult: {},
    inputSearch: '',
  },
  reducers: {
    setResults(state: any, payload: any) {
      return {
        ...state,
        searchResults: payload?.results,
        resultsLoaded: true,
        inputSearch: payload?.term,
        errorOnResults: payload?.errorOnResults,
      };
    },

    setCurrentResult(state: any, payload: any) {
      let payloadId: string = '';

      let orderedCurrResult = {};

      if (payload?.hasOwnProperty('account_id')) {
        payloadId = payload?.account_id;
        orderedCurrResult = functions.orderItemProperties(payload, 'user');
      } else if (payload?.hasOwnProperty('company_id')) {
        payloadId = payload?.company_id;
        orderedCurrResult = functions.orderItemProperties(payload, 'company');
      } else if (payload.hasOwnProperty('device_id')) {
        payloadId = payload?.device_id;

        if (functions.checkTypeOfDev(payload) === 'sensor') {
          orderedCurrResult = functions.orderItemProperties(payload, 'sensor');
        } else {
          let editedObject = functions.rmvUnecessProps(payload);

          orderedCurrResult = functions.orderItemProperties(
            editedObject,
            'device',
          ); //used only in the search result devs modal - to reduce the quantity of props being rendered
        }
      }

      localStorage.setItem('currentResult', payloadId);

      if (payload) {
        return {
          ...state,
          currentResult: payload,
          orderedCurrResult: orderedCurrResult,
        };
      } else {
        return { ...state };
      }
    },

    defResult(state: any, payload: any) {
      let stringifiedResult: any = JSON.stringify(payload);

      localStorage.setItem('currentResult', stringifiedResult);

      return {
        ...state,
        currentResult: payload,
      };
    },

    defSearchTerm(state: any, payload: any) {
      return {
        ...state,
        searchTermInput: payload,
      };
    },
  },
  effects: (dispatch: any) => ({
    async searchByTerm(term: string) {
      const self: any = this;

      let filteredInactiveResults;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.search`,
        null,
        { input: term },
      );
      if (response?.statusCode === 200) {
        filteredInactiveResults = response?.body?.data?.filter(
          (item: any) =>
            !item?.hasOwnProperty('status') ||
            // (item.hasOwnProperty('status') && item?.status === 'active'),
            (item.hasOwnProperty('status') && item?.status !== 'inactive'),
        );

        self.setResults({
          results: filteredInactiveResults,
          term,
          errorOnResults: false,
        });
      } else {
        self.setResults({ results: [], term, errorOnResults: true });
      }

      return response;
    },
  }),
};

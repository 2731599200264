import React, { Fragment } from 'react';
import { JumboCardProps } from './interface';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { FaRegCopy } from 'react-icons/fa';
import { Contacts } from '..';
import { TooltipView, GenTooltip } from 'business/modules/admin/common';
import './styles.scss';

const JumboCard: React.FC<JumboCardProps> = ({
  config,
  returnLabel,
  formatDate,
  filteredObj,
  functions,
}) => {
  return (
    <div className="card" id="spec-margin-card">
      <div className="card-body">
        <h5 className="card-title ct-csubt">{config?.strings?.cardTitle}</h5>
        <h6 className="card-subtitle text-muted ct-csubt">
          <IoInformationCircleOutline className="the-icon" />{' '}
          {config?.strings?.cardSubtitle}
        </h6>

        <div className="card-text" style={{ position: 'relative', zIndex: 2 }}>
          <div className="container z-side-padding--1">
            <div className="row mt-15p">
              {Object.entries<any>(filteredObj).map(([key, value], idx) => {
                if (key === 'contact') return null;
                return (
                  <div
                    className="col-xs-12 col-sm-12 col-md-4 information-spec-cols"
                    key={idx}
                  >
                    <Fragment key={key}>
                      <span className="lead smaller-lead-ft--1">
                        {returnLabel(key)}
                      </span>

                      {functions.keyIsDate(key) ? (
                        <span className="lead smaller-lead-ft--1">
                          {formatDate(value, key)}
                        </span>
                      ) : (
                        <>
                          {key !== 'contact' && (
                            <span
                              className={`lead smaller-lead-ft--1 ${
                                key === `company_id` ? `break-word-all` : ``
                              }`}
                            >
                              {value}
                              {key === 'code' && (
                                <TooltipView
                                  title={
                                    <GenTooltip
                                      title={config.tooltip.title}
                                      textOne={config.tooltip.txt}
                                    />
                                  }
                                >
                                  <span>
                                    <button type="button">
                                      <FaRegCopy
                                        style={{
                                          color: '#000000',
                                          strokeWidth: '30',
                                          stroke: 'white',
                                          marginLeft: '2px',
                                        }}
                                        className="copy-to-clipboard-icon fareg-i"
                                        onClick={() =>
                                          functions.copyToClipboard(
                                            value,
                                            'close--alert--clipboard3',
                                          )
                                        }
                                      />{' '}
                                    </button>
                                  </span>
                                </TooltipView>
                              )}
                            </span>
                          )}
                        </>
                      )}
                    </Fragment>
                  </div>
                );
              })}
            </div>

            <Contacts filteredObj={filteredObj} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JumboCard;

import React from 'react';
import DetailSection from '../DetailSection';
import { Row } from 'business/modules/admin/common';
import './styles.scss';

interface ResultDetailsProps {
  item: any;
  name: any;
  resultType: any;
}

const ResultDetails: React.FC<ResultDetailsProps> = ({
  item,
  name,
  resultType,
}) => {
  const handleInitials = (str: string) => {
    let name = str.split(' ');

    if (name && name.length === 1) {
      return <>{name[0][0].toUpperCase()}</>;
    } else if (name && name.length > 1) {
      return (
        <>
          {name[0][0].toUpperCase()}
          {name[1][0].toUpperCase()}
        </>
      );
    }

    return '';
  };

  return (
    <>
      <div className="container results-detailed-info">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-4">
            <div className="card pt1 mbtm-0">
              <div className="circle-result card-img-top mbtm-0">
                {handleInitials(name)}
              </div>

              <div className="card-body">
                <h5 className="card-title">{item?.name}</h5>

                <Row
                  outClass={`d-inline`}
                  firstClass={`inline-label lead lead-sm`}
                  firstContent={<>Result type: </>}
                  secondClass={`lead lead-sm`}
                  secondContent={resultType}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-8">
            <div className="row">
              <div className="card-body mt-25" style={{ paddingBottom: '0px' }}>
                <h5 className="card-title">Information</h5>

                <DetailSection item={item} resultType={resultType} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginBottom: '8px' }}>
        <hr className="section-div" />
      </div>
    </>
  );
};

export default ResultDetails;

import React, { useEffect, useState, Fragment } from 'react';
import config from './config';
import validate from 'validate.js';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { EditCompanyProps } from './interface';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  Modal,
  Notification,
  Template,
  AutoSuggest,
  Label,
  TooltipView,
  GenTooltip,
} from 'business/modules/admin/common';
import {
  GenerateID,
  IdInput,
  NoPhoneNorCode,
  FormFooter,
  PhoneInput,
} from '../../common';
import { FaRegCopy } from 'react-icons/fa';
import { State, City } from 'country-state-city';
import ZipCodes from 'zipcodes';
import './styles.scss';

const COUNTRY_CODE = 'US';

const EditCompany: React.FC<EditCompanyProps> = ({
  getTheCompaniesList,
  currentCompanyToEdit,
  defineCompanyToEdit,
  currentlyEmptyFieldsEdition,
  resetToUpdatedState,
  userClearedEd,
  detectEmptyFieldsOnEditing,
  updateCurrCompany,
  compItem,
  handleModalState,
  modalMustClose,
  saveCompItemGlobally,
  loading,
}) => {
  const history = useHistory();
  const [persistentComp, setPersistentComp] = useState(config.persistentComp);
  const [readyData, setReadyData] = useState(false);
  const [editPh, setEditPh] = useState('');
  const [errors, setErrors]: any = useState(null);
  const [currentField, setCurrentField] = useState('');
  const [allStates, setAllStates]: any = useState([]);
  const [allCities, setAllCities]: any = useState([]);
  const [allCityZipCodes, setAllCityZipCodes]: any = useState([]);
  const [currentCompany, setCurrentCompany] = useState<any>({});
  const [userQuits, setUserQuits] = useState(false);
  const [formKey, setFormKey] = useState('formKey');
  const [dataArrs, setDataArrs] = useState<any>({
    states: [],
    cities: [],
    zips: [],
  });
  const [suggs, setSuggests] = useState<any>({
    device_name: '',
    device_city: '',
    device_zipcode: '',
  });

  const _labels: any = {
    'company-id': 'Company ID',
  };

  const copyContent = async (copyTxtToClip: any) => {
    try {
      await navigator.clipboard.writeText(copyTxtToClip);
    } catch (err) {
      //handle error copying to clipboard
    }
  };

  const copyToClipboard = (code: any, field: any) => {
    copyContent(code);

    Notification({
      title: 'Success',
      message: `${_labels[field.name]} copied to clipboard.`,
      type: 'success',
    });
  };

  const handleClear = () => {
    setUserQuits(true);

    setCurrentCompany({
      ...currentCompany,
      name: currentCompanyToEdit?.name,
      email: currentCompanyToEdit?.contact?.email,
      phone: currentCompanyToEdit?.contact?.phone,
      code: currentCompanyToEdit?.code,
      city: {
        name: currentCompanyToEdit?.city,
        value: currentCompanyToEdit?.city,
      },
      state: {
        name: currentCompanyToEdit?.state,
        value: currentCompanyToEdit?.state,
      },
      zipcode: { value: currentCompanyToEdit?.zipcode },
    });
    setEditPh(currentCompanyToEdit?.contact?.phone);

    //remove warning
    detectEmptyFieldsOnEditing(false);

    setErrors();

    setSuggests({
      ...suggs,
      device_city: currentCompanyToEdit?.city,
      device_state: currentCompanyToEdit?.state,
      device_zipcode: currentCompanyToEdit?.zipcode,
    });

    setTimeout(() => {
      setUserQuits(false);
    }, 500);

    setFormKey('form_new_key');
  };

  const updateCompany = async () => {
    const updatedCompanyData: any = {
      name: currentCompany?.name,
      code: currentCompany?.code,
      phone: editPh,
      email: currentCompany?.email,
      state: currentCompany?.state?.name,
      city: currentCompany?.city?.name,
      zipcode: currentCompany?.zipcode?.value,
    };

    loading.start('Updating company details...');

    let response: any = await updateCurrCompany({
      data: updatedCompanyData,
      id: compItem?.company_id,
    });

    if (response?.statusCode === 200) {
      const toSave: any = { ...response?.body?.data };
      defineCompanyToEdit(toSave);

      Notification({
        title: 'Success!',
        message: config?.notifications?.edit?.success,
        type: 'success',
      });

      setCurrentCompany({
        ...currentCompany,
        name: response?.body?.data?.name,
        email: response?.body?.data?.contact?.email,
        phone: response?.body?.data?.contact?.phone,
        code: response?.body?.data?.code,
        city: {
          name: response?.body?.data?.city,
          value: response?.body?.data?.city,
        },
        state: {
          name: response?.body?.data?.state,
          value: response?.body?.data?.state,
        },
        zipcode: { value: response?.body?.data?.zipcode },
      });

      setEditPh(response?.body?.data?.contact?.phone);

      setSuggests({
        ...suggs,
        device_city: response?.body?.data?.city,
        device_state: response?.body?.data?.state,
        device_zipcode: response?.body?.data?.zipcode,
      });

      setPersistentComp(toSave);

      saveCompItemGlobally(toSave);
    } else {
      Notification({
        title: 'Error!',
        message: config?.notifications?.edit?.error,
        type: 'error',
      });
    }

    loading.stop();

    await getTheCompaniesList();
  };

  const handlePhoneEdit = async (value: any) => {
    let currentFields: any = { phone: value };
    let currentConstraints: any = {
      phone: {
        ...config.constraints['phone' as keyof typeof config.constraints],
      },
    };

    setEditPh(value);

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: '' };
        });
      })
      .catch(err => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: err.phone };
        });
      });
  };

  const handleEditInput = async (event: any) => {
    let name: any = event.target.name;
    resetToUpdatedState(false);

    let currentFields: any = { [name]: event.target.value };

    let currentConstraints: any = {
      [name]: {
        ...config.constraints[name as keyof typeof config.constraints],
      },
    };

    setCurrentCompany({
      ...currentCompany,
      [event.target.name]: event.target.value,
      phone: editPh,
    });

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: '' };
        });
      })
      .catch((err: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: err[name] };
        });
      });
  };

  const formatZipCodes = (zipCodes: any) => {
    return zipCodes.map((zpCode: any) => {
      const { zip } = zpCode;
      return {
        ...zpCode,
        value: zip,
        label: zip,
      };
    });
  };

  const formatLocations = (data: any) => {
    return data.map((item: any) => {
      const { name, isoCode } = item;
      return {
        ...item,
        label: name,
        value: isoCode ? isoCode : name,
      };
    });
  };

  const initAllStates = () => {
    let allStates: any = State.getStatesOfCountry(COUNTRY_CODE);
    allStates = formatLocations(allStates);
    setAllStates(allStates);

    const stateNames = allStates.map((elem: any) => elem.name);
    setDataArrs({ ...dataArrs, states: stateNames });

    return allStates;
  };

  const retrieveList = (field: string) => {
    switch (field) {
      case 'device_state':
        return allStates;
      case 'device_city':
        return allCities;
      case 'device_zipcode':
        return allCityZipCodes;
      default:
        return [];
    }
  };

  const grabSuggestion = (id: string, currentSuggestion: string) => {
    //mark which input is being selected, in order to handle the error display
    setCurrentField(id);
    setSuggests({ ...suggs, [id]: currentSuggestion });
  };

  const validateInputtedVals = (suggs: any) => {
    if (userQuits) {
      setErrors();
      return;
    }

    switch (currentField) {
      case 'device_state':
        if (dataArrs?.states?.includes(suggs?.device_state)) {
          setErrors({
            ...errors,
            device_state: '',
          });
        } else {
          setErrors({
            ...errors,
            device_state: 'Please select a state from the list',
          });
        }
        break;

      case 'device_city':
        if (dataArrs?.cities?.includes(suggs?.device_city)) {
          setErrors({
            ...errors,
            device_city: '',
          });
        } else {
          setErrors({
            ...errors,
            device_city: 'Please select a city from the list',
          });
        }
        break;
      case 'device_zipcode':
        if (dataArrs?.zips?.includes(suggs?.device_zipcode)) {
          setErrors({
            ...errors,
            device_zipcode: '',
          });
        } else {
          setErrors({
            ...errors,
            device_zipcode: 'Please select a zipcode from the list',
          });
        }
        break;

      default:
        break;
    }
  };

  const returnValue = (formField: any) => {
    return formField === `device_state`
      ? currentCompany?.state?.name
      : formField === `device_city`
      ? currentCompany?.city?.name
      : formField === `device_zipcode`
      ? currentCompany?.zipcode?.value
      : ``;
  };

  const handleField = (field: any) => {
    switch (field) {
      case 'device_state':
        return 'state';
      case 'device_city':
        return 'city';
      case 'device_zipcode':
        return 'zipcode';
      default:
        return '';
    }
  };

  const prepareFields = () => {
    initAllStates();
  };

  useEffect(() => {
    //Handle page refresh
    let storedCurrCmp: any = localStorage.getItem('@currentCompanyToEdit');
    let storedCurrCompanyItem: any = localStorage.getItem('@companyItem');

    if (storedCurrCmp && storedCurrCompanyItem) {
      let parsed = JSON.parse(storedCurrCmp);
      let parsedCompItem: any = JSON.parse(storedCurrCompanyItem);

      setPersistentComp(parsed);
      setReadyData(true);

      setCurrentCompany({
        ...currentCompany,
        name: parsed?.name,
        email: parsed?.contact?.email,
        phone: parsed?.contact?.phone,
        code: parsed?.code,
        city: { name: parsed?.city, value: parsed?.city },
        state: { name: parsed?.state, value: parsed?.state },
        zipcode: { value: parsed?.zipcode },
      });

      setEditPh(parsed?.contact?.phone);

      setSuggests({
        ...suggs,

        device_city: parsed?.city,
        device_state: parsed?.state,
        device_zipcode: parsed?.zipcode,
      });

      prepareFields();
      defineCompanyToEdit(parsed);
      saveCompItemGlobally(parsedCompItem);
    }
  }, []);

  useEffect(() => {
    if (
      Object.keys(currentCompany).length > 0 &&
      editPh &&
      Object.keys(suggs).length > 0
    ) {
      prepareFields();
    }
  }, [currentCompany, editPh, suggs]);

  //fire the warning and prevent submit data with missing fields
  useEffect(() => {
    if (
      functions.checkEmptyValues(suggs, currentCompany, editPh) ||
      functions.handleFormErrors(errors)
    ) {
      detectEmptyFieldsOnEditing(true);
    } else {
      detectEmptyFieldsOnEditing(false);
    }
  }, [editPh, currentCompany, suggs, errors]);

  useEffect(() => {
    if (currentCompany?.state?.label) {
      const { name, value } = currentCompany.state;
      let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
      tempCities = formatLocations(tempCities);
      setAllCities([...tempCities]);

      //to posterior handling suggestion errs:
      const cityNames = tempCities.map((elem: any) => elem.name);

      setDataArrs({ ...dataArrs, cities: cityNames });
    }
  }, [currentCompany?.state?.label]);

  useEffect(() => {
    if (currentCompany?.city?.label) {
      const { name, stateCode, latitude, longitude } = currentCompany.city;
      let tempZipCodesByCoords: any = ZipCodes.lookupByCoords(
        latitude,
        longitude,
      );
      let tempZipCodes: any = ZipCodes.lookupByName(name, stateCode);
      tempZipCodes = formatZipCodes(
        tempZipCodes && tempZipCodes.length > 0
          ? tempZipCodes
          : [tempZipCodesByCoords],
      );

      setAllCityZipCodes([...tempZipCodes]);

      //to posterior handling suggestion errs:
      const zipNums = tempZipCodes.map((elem: any) => elem.zip);

      setDataArrs({ ...dataArrs, zips: zipNums });
    }
  }, [currentCompany?.city?.label]);

  useEffect(() => {
    validateInputtedVals(suggs);
  }, [suggs]);

  return (
    <Template
      id="dashboard-edit-company"
      title={config?.companyEdit?.title}
      goBack={() => {
        history.push('/admin/dashboard/companies-list');
      }}
    >
      <BreadcrumbsItem to={config?.companyEdit?.breadcrumbItem}>
        {config.strings.breadcrumbEdit}
      </BreadcrumbsItem>

      <div className={config.companyEdit.class} id="edit--company--screen">
        <h4 className="centered">{config.companyEdit.mainTitle} </h4>
        <p className="lead centered card-top-ft">{config.companyEdit.pText}</p>
        <hr className="my-4" />

        <div className="container id-edit-company--form--01" key={formKey}>
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5 the-form-col1 left-side">
              {config.editFields.map((field: any, index: number) => {
                if (field.isPhone) {
                  return (
                    <div className="form-group id-input-elem" key={index}>
                      <Label className={`label-cmp-6`}>
                        {field?.label}
                        <span className="required-ast">*</span>
                      </Label>
                      <PhoneInput
                        key={index}
                        value={editPh}
                        index={index}
                        handleChange={(value: any) => handlePhoneEdit(value)}
                        handleChangeError={() => {}}
                        name={field.name}
                        configInputs={
                          config.constraints[
                            field.name as keyof typeof config.constraints
                          ]
                        }
                        errors={errors ? errors[field.name] : ''}
                        editMode={``}
                      />
                    </div>
                  );
                }
                if (field.isCode) {
                  return (
                    <div className="form-group id-input-elem" key={index}>
                      <Label className={`label-cmp-6`}>
                        {field.label}

                        <TooltipView
                          title={
                            <GenTooltip
                              title={`Grab the ${_labels[field?.name]}`}
                              textOne={`Copy the ${
                                _labels[field?.name]
                              } to clipboard`}
                            />
                          }
                        >
                          <span>
                            <button
                              type="button"
                              id="create-clt-fareg-btn2"
                              onClick={() =>
                                copyToClipboard(currentCompany?.code, field)
                              }
                            >
                              <FaRegCopy
                                style={{
                                  color: '#000000',
                                  strokeWidth: '1',
                                  stroke: 'white',
                                }}
                                className="copy-to-clipboard-icon-clt fareg-i"
                              />
                            </button>
                          </span>
                        </TooltipView>
                      </Label>

                      <div className="input-group">
                        <IdInput
                          index={index}
                          elem={field}
                          generatedCode={currentCompany?.code || ''}
                          newCodeGenerator={
                            <GenerateID
                              onClick={() => {}}
                              disabledGenerate={true}
                              label={config.genLabel[`edit`]}
                            />
                          }
                        />{' '}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <Fragment key={field.id}>
                      <NoPhoneNorCode
                        className={`label-cmp-6`}
                        required={<span className="required-ast">*</span>}
                        field={field}
                        index={index}
                        elem={field}
                        data={currentCompany}
                        getUserInput={(event: any) => handleEditInput(event)}
                        err={errors ? errors[field.name] : ''}
                      />
                    </Fragment>
                  );
                }
              })}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5 the-form-col1 right-side">
              {config.formConfig.map((formField: any, index: number) => {
                return (
                  <div className={formField.className} key={index}>
                    <div className="input-group">
                      <label className={`label-cmp-6`}>
                        {formField.label}
                        <span className="required-ast">*</span>
                      </label>
                    </div>
                    {allStates.length > 0 && (
                      <div
                        className={
                          errors &&
                          errors.hasOwnProperty(formField?.field) &&
                          errors[formField?.field].length > 0
                            ? 'auto-sugg-errclass'
                            : ''
                        }
                      >
                        <AutoSuggest
                          grabSuggestion={grabSuggestion}
                          dataWasCleared={userClearedEd}
                          id={formField.field}
                          handleChange={(newState: any) => {
                            setCurrentCompany((prevState: any) => {
                              return {
                                ...prevState,
                                [handleField(formField.field)]: { ...newState },
                                phone: editPh,
                              };
                            });
                          }}
                          data={retrieveList(formField.field)}
                          value={returnValue(formField.field)}
                          {...formField.fields}
                        />
                      </div>
                    )}
                    <span className="render-error">
                      {errors && errors[formField.field]}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-xs-12 col-sm-12 col-lg-5 col-xl-5 the-form-col1 left-side"></div>

          <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5 the-form-col1 right-side">
            {readyData && (
              <FormFooter
                rowClass="row action-btns"
                className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pdg`}
                emptyFields={currentlyEmptyFieldsEdition}
                dataTarget={`#save-changes-to-current-company`}
                onClick={() => {
                  handleClear();
                  setFormKey('clear');
                }}
                onClickValidate={() => {}}
              />
            )}
          </div>
        </div>

        {currentlyEmptyFieldsEdition && (
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-12 pdg alert--col1">
              <div
                className="alert alert-info mt-2 alert-w alert-pl"
                role="alert"
              >
                <span className="required-ast">*</span> {config.strings.warning}
              </div>
            </div>
          </div>
        )}

        <Modal
          isAnEditModal={true}
          config={config}
          bodyClass={functions.handleOpts(`edit__comp`, 'bodyClass')}
          className={`small-button ok`}
          onClick={() => {
            updateCompany();
          }}
          onClickCancel={() => {
            setFormKey('reset');

            handleClear();
          }}
          title={functions.handleOpts(`edit__comp`, `title`)}
          id={`save-changes-to-current-company`}
          icon={functions.handleOpts(`edit__comp`, `icon`)}
          body={functions.handleOpts(`edit__comp`, 'bodies')}
          modalMustClose={modalMustClose}
          handleModalState={handleModalState}
          label={config.labels[`edit__comp` as keyof typeof config.labels]}
          hasConfirmBtn={true}
          sureToProceed={config.modalStrings.sureToProceed}
        />
      </div>
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminCompaniesState } = state;

  return {
    compItem: adminCompaniesState.compItem,
    modalMustClose: adminCompaniesState.modalMustClose,
    userClearedEd: adminCompaniesState.userClearedEd,
    currentCompanyToEdit: adminCompaniesState.currentCompanyToEdit,
    currentlyEmptyFieldsEdition:
      adminCompaniesState.currentlyEmptyFieldsEdition,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminCompaniesState, loader } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    resetToUpdatedState: adminCompaniesState.resetToUpdatedState,
    detectEmptyFieldsOnEditing: adminCompaniesState.detectEmptyFieldsOnEditing,
    updateCurrCompany: adminCompaniesState.updateCurrCompany,
    handleModalState: adminCompaniesState.handleModalState,
    getTheCompaniesList: adminCompaniesState.getTheCompaniesList,
    defineCompanyToEdit: adminCompaniesState.defineCompanyToEdit,
    saveCompItemGlobally: adminCompaniesState.saveCompItemGlobally,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCompany);

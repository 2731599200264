export const cols: any = {
  user: {
    first: 'col-xs-12 col-sm-12 col-md-6 pdg',
    second: 'col-xs-12 col-sm-12 col-md-6 pdg',
  },
  device: {
    first: 'col-xs-12 col-sm-12 col-md-6 pdg',
    second: 'col-xs-12 col-sm-12 col-md-6 pdg',
  },
  sensor: {
    first: 'col-xs-12 col-sm-12 col-md-6 pdg',
    second: 'col-xs-12 col-sm-12 col-md-6 pdg',
  },
  company: {
    first: 'col-xs-12 col-sm-12 col-md-6 pdg',
    second: 'col-xs-12 col-sm-12 col-md-6 pdg',
  },
};

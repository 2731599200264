import config from '../../config/config';

export const jitG3Iot = {
  basesURL: {
    baseURLs: config.apis.g3iot.baseURLs,
    baseSocketURLs: config.apis.jitBE.baseSocketURLs,
  },
  logs: {
    list: {
      path: '/logs/list?items=5',
      description: 'Device logs list',
      cache: {
        enabled: false,
      },
      headers: {},
      method: 'get',
    }
  },

};

export default {
  strings: {
    title: 'Search results',
    noResults: 'No results found...',
    timeSlLabel: '',
    breadcrumbItemTo: `/admin/dashboard/search-results`,
    breadcrumbTitle: `Search results`,
    loading: 'Loading results...', // delReqs
  },
  menu: [
    {
      id: `firstOpt`,
      label: `View`,
      dataTarget: '#view-search-result',
      dataToggle: `modal`,
    },
    // {
    //   id: `secondOpt`,
    //   label: `Other`,
    //   dataTarget: `#`,
    //   dataToggle: `modal`,
    // },
  ],

  rowClasses: {
    col1: `col-xs-12 col-sm-12 col-md-3 col-lg-3 res-col-align`,
    span1: `lead lead-adm bolder-ft the-name-with-the-circle1`,
    col2: `col-xs-12 col-sm-12 col-md-3 col-lg-2 res-col-align`,
    span2: `lead lead-adm location-span2`,
    col3: `col-xs-12 col-sm-12 col-md-2 col-lg-3 res-col-align`,
    span3: `lead lead-adm location-span1`,
    col4: `col-xs-12 col-sm-12 col-md-2 col-lg-2 optional-col res-col-align`,
    span4: `lead lead-adm location-span1`,
    colMenu: `col-xs-12 col-sm-12 col-md-2 col-lg-2 btn-vab cent-col-contents1`,
  },
  modal: {
    view: {
      title: 'View result',
    },
  },
  modalStrings: {
    labelOK: ` OK`,
  },
};

import React from 'react';

const filterConfigs = (setts: any) => {
  return Object.keys(setts?.conf)
    .filter(key => key !== 'conf')
    .reduce((filtered: any, key: string) => {
      filtered[key as keyof typeof filtered] = setts.conf[key];
      return filtered;
    }, {});
};

const filterSettings = (setts: any) => {
  return Object.keys(setts)
    .filter(key => key !== 'conf')
    .reduce((filtered: any, key: string) => {
      filtered[key as keyof typeof filtered] = setts[key];
      return filtered;
    }, {});
};

const handleValueToDisplay = (value: any) => {
  if (typeof value === 'string' || typeof value === 'number') {
    return <span className="lead sm-lead">{value}</span>;
  } else if (typeof value === 'boolean') {
    return <span className="lead sm-lead">{value.toString()}</span>;
  } else if (Array.isArray(value)) {
    if (value.length === 0) {
      return <span className="lead sm-lead">{`No values`}</span>;
    } else {
      return null;
    }
  } else if (value === null) {
    return <span className="lead sm-lead">{`null`}</span>;
  } else if (!value && value !== null) {
    return <span className="lead sm-lead">{`No values`}</span>;
  }
  return <span className="lead sm-lead">{JSON.stringify(value)}</span>;
};

const getTheID = (cdv: any, index: number) => {
  return cdv && cdv?.cdid ? cdv?.cdid : cdv?.dmac ? cdv?.dmac : index;
};

const isPhone = (key: string, value: any) => {
  if (
    key === 'phones' &&
    value &&
    Array.isArray(value) &&
    value.length > 0 &&
    value[0].hasOwnProperty('phone')
  ) {
    return true;
  } else return false;
};

const isAddress = (key: string, value: any) => {
  if (
    key === 'addresses' &&
    value &&
    value?.length > 0 &&
    value[0]?.address_line_one &&
    value[0]?.city &&
    value[0]?.zip_code &&
    value[0]?.state
  ) {
    return true;
  } else return false;
};

const isOb = (item: any) => {
  if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
    return true;
  } else return false;
};

export const helpers = {
  filterConfigs: filterConfigs,
  isPhone: isPhone,
  isAddress: isAddress,
  isOb: isOb,
  handleValueToDisplay: handleValueToDisplay,
  getTheID: getTheID,
  filterSettings: filterSettings,
};

import moment from 'moment';

export default {
  strings: {
    titleLabel: 'System',
    loading: 'Loading Data...',
    reTryLoading: 'Trying reload Data...',
    tmzLabel: 'Current Timezone',
    defaultTimezone: 'Greenwich Mean Time',
  },
  threshTags: ['out_temp_max', 'out_temp_min', 'out_hum_value', 'out_aq_value', 'in_pm25_value', 'in_co2_value', 'in_voc_value', 'curr_mode'],
  table: {
    pageOptions: [
      { value: 1, label: '1 Period' },
      { value: 2, label: '2 Periods' },
      { value: 3, label: '3 Periods' },
      { value: 4, label: '4 Periods' },
      { value: 5, label: '5 Periods' },
      { value: 6, label: '6 Periods' },
    ],
    columns: [
      { title: 'Date', field: 'date', defaultSort: 'desc', customSort: (itemA: any, itemB: any) => {
        return moment(itemA.sortDate).valueOf() - moment(itemB.sortDate).valueOf();
      } },
      { title: 'Time', field: '', hidden: true },
      { title: 'Current', field: 'time' },
      { title: 'Minutes Left', field: 'min_hour' },
      { title: 'Central Fan', field: 'cfn_state' },
      { title: 'Damper', field: 'dmp_state' },
      { title: 'Exhaust', field: 'ext_state', hidden: true },
      { title: 'State', field: 'ext_state' },
      { title: 'Light', field: 'lgt_state' },
      { title: 'Fresh Air Flow', field: 'air_flow' },
      { title: 'Thermostat', field: 'ths_state' },
      { title: 'OutsideAir', field: 'out_', hidden: true },
      { title: 'Temp', field: 'out_temp' },
      { title: 'Hum', field: 'out_hum' },
      { title: 'Air Quality', field: 'out_aq' },
      { title: 'IndoorAir', field: 'in_', hidden: true },
      { title: 'PM2.5', field: 'in_pm25' },
      { title: 'CO2', field: 'in_co2' },
      { title: 'VOC', field: 'in_voc' },
      { title: 'Temp', field: 'in_temp' },
      { title: 'Hum', field: 'in_hum' },
      { title: 'Events', field: 'system_events' },
    ],
    columnsKeys: [
      { title: 'Date', field: 'date', defaultSort: 'desc', customSort: (itemA: any, itemB: any) => {
        return moment(itemA.sortDate).valueOf() - moment(itemB.sortDate).valueOf();
      } },
      { title: 'Current', field: 'time' },
      { title: 'Minutes Left', field: 'min_hour' },
      { title: 'Central Fan', field: 'cfn_state' },
      { title: 'Damper', field: 'dmp_state' },
      { title: 'State', field: 'ext_state' },
      { title: 'Light', field: 'lgt_state' },
      { title: 'Fresh Air Flow', field: 'air_flow' },
      { title: 'Thermostat', field: 'ths_state' },
      { title: 'Temp', field: 'out_temp' },
      { title: 'Hum', field: 'out_hum' },
      { title: 'Air Quality', field: 'out_aq' },
      { title: 'PM2.5', field: 'in_pm25' },
      { title: 'CO2', field: 'in_co2' },
      { title: 'VOC', field: 'in_voc' },
      { title: 'Temp', field: 'in_temp' },
      { title: 'Hum', field: 'in_hum' },
      { title: 'Events', field: 'system_events' },
    ],
    options: {
      exportButton: true,
      search: true,
      sorting: true,
      paginationType: 'normal',
    },
    totalCount: (24 * 70),
    defaultOptions: {
      pageSize: 60,
      pageSizeOptions: () => {
        const totalRows: number = 80 * 24;
        let totalOptions: any = [];

        for (let index = 0; index < totalRows; index++) {
          totalOptions.push(index);
        }

        return totalOptions;
      },
    },
  },
  timezones: [
    {
      label: 'Newfoundland Time w/ Daylight Savings Time',
      timezone: 'Canada/Newfoundland',
    },
    {
      label: 'Atlantic Time w/ Daylight Savings Time',
      timezone: 'Canada/Atlantic',
    },
    {
      label: 'Eastern Standard w/ Daylight Savings Time',
      timezone: 'Canada/Eastern',
    },
    {
      label: 'Central Standard w/ Daylight Savings Time',
      timezone: 'US/Central',
    },
    {
      label: 'Mountain Standard w/ Daylight Savings Time',
      timezone: 'US/Mountain',
    },
    {
      label: 'Mountain Standard Time',
      timezone: 'US/Mountain',
    },
    {
      label: 'Pacific Standard w/ Daylight Savings Time',
      timezone: 'US/Pacific',
    },
    {
      label: 'Alaska Standard w/ Daylight Savings Time',
      timezone: 'US/Alaska',
    },
    {
      label: 'Hawaii Standard w/ Daylight Savings Time',
      timezone: 'US/Hawaii',
    },
  ],
};

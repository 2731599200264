import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { SearchResults } from './pages';

const base_path = '/admin/dashboard';

const SearchRoutes: React.FC = ({ ...props }: any) => {
  return (
    <Switch>
      <Route
        exact
        path={`${base_path}/search-results`}
        component={SearchResults}
      />
    </Switch>
  );
};

export default SearchRoutes;

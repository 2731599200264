import React, { Fragment, useState } from 'react';
import { UserDetailsProps } from './interface';
import {
  GenTooltip,
  TooltipView,
  Notification,
} from 'business/modules/admin/common';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { functions } from './helpers';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FaRegCopy } from 'react-icons/fa';
import './styles.scss';

const UserDetails: React.FC<UserDetailsProps> = ({ config, item, details }) => {
  const [tokenIsVisible, setTokenIsVisible] = useState<boolean>(false);

  const labels: any = {
    client_user: `Client User`,
    client_secret: `Client Secret`,
  };

  const toggleShowToken = () => {
    setTokenIsVisible(!tokenIsVisible);
  };

  const copyContent = async (copyTxtToClip: any) => {
    try {
      await navigator.clipboard.writeText(copyTxtToClip);
    } catch (err) {
      //handle error copying to clipboard
    }
  };

  const copyToClipboard = (item: any, id: string) => {
    copyContent(item);

    Notification({
      title: 'Success',
      message: `${labels[id]} copied to clipboard.`,
      type: 'success',
    });
  };

  return (
    <div className="jumbotron" id="dev-user-accordion-details--1">
      <div id="top--section--2">
        <h6 className="display-5">{config?.strings?.main}</h6>
        <p className="lead smlead">
          {config?.strings?.lead}
          <span className="stronger-ft">{item?.client_user}</span>.
        </p>
      </div>

      <div className="card" id="spec-margin-card--2">
        <div className="card-body">
          <h5 className="card-title ct-csubt">{config?.strings?.cardTitle}</h5>
          <h6 className="card-subtitle text-muted ct-csubt">
            <IoInformationCircleOutline className="the-icon" />{' '}
            {config?.strings?.cardSubtitle}
          </h6>

          <div className="card-text card--text-s">
            <div className="container pdg">
              <div className="row mt-15p">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 information-spec-cols">
                  {Object.entries<any>(details)
                    .slice(0, 4)
                    .map(([key, value], idx) => {
                      return (
                        <div
                          className="col-xs-12 col-sm-12 information-spec-cols"
                          key={idx}
                        >
                          <Fragment key={key}>
                            <span className="lead smaller-lead-ft--1">
                              {functions.returnLabel(key)}
                            </span>

                            {functions.keyIsDate(key) ? (
                              <span className="lead smaller-lead-ft--1">
                                {functions.formatDate(value, key)}
                              </span>
                            ) : (
                              <span className={`lead smaller-lead-ft--1`}>
                                {value}
                              </span>
                            )}
                          </Fragment>
                        </div>
                      );
                    })}

                  {Object.entries<any>(item)
                    .slice(3, 4)
                    .map(([key, value], idx) => {
                      return (
                        <div
                          className="col-xs-12 col-sm-12 information-spec-cols"
                          key={idx}
                        >
                          <Fragment key={key}>
                            <span className="lead smaller-lead-ft--1">
                              {functions.returnLabel(key)}
                            </span>

                            {functions.keyIsDate(key) ? (
                              <span className="lead smaller-lead-ft--1">
                                {functions.formatDate(value, key)}
                              </span>
                            ) : (
                              <span className={`lead smaller-lead-ft--1`}>
                                {value}
                              </span>
                            )}
                          </Fragment>
                        </div>
                      );
                    })}
                </div>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 information-spec-cols">
                  {Object.entries<any>(item)
                    .slice(0, 1)
                    .map(([key, value], idx) => {
                      return (
                        <div
                          className="col-xs-12 col-sm-12 information-spec-cols"
                          key={idx}
                        >
                          <Fragment key={key}>
                            <span className="lead smaller-lead-ft--1">
                              {functions.returnLabel(key)}
                            </span>

                            {functions.keyIsDate(key) ? (
                              <span className="lead smaller-lead-ft--1">
                                {functions.formatDate(value, key)}
                              </span>
                            ) : (
                              <span className={`lead smaller-lead-ft--1`}>
                                {value}
                              </span>
                            )}
                          </Fragment>
                        </div>
                      );
                    })}

                  {Object.entries<any>(details)
                    .slice(4, Math.ceil(Object.entries<any>(item).length))
                    .map(([key, value], idx) => {
                      return (
                        <div
                          className="col-xs-12 col-sm-12 information-spec-cols"
                          key={idx}
                        >
                          <Fragment key={key}>
                            <span className="lead smaller-lead-ft--1">
                              {functions.returnLabel(key)}
                            </span>

                            {functions.keyIsDate(key) ? (
                              <span className="lead smaller-lead-ft--1">
                                {functions.formatDate(value, key)}
                              </span>
                            ) : (
                              <span className={`lead smaller-lead-ft--1`}>
                                {value}
                              </span>
                            )}
                          </Fragment>
                        </div>
                      );
                    })}
                </div>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-5 information-spec-cols">
                  {Object.entries<any>(item)
                    .slice(1, 2)
                    .map(([key, value], idx) => {
                      return (
                        <div
                          className="col-xs-12 col-sm-12 information-spec-cols"
                          key={idx}
                        >
                          <Fragment key={key}>
                            <span className="lead smaller-lead-ft--1">
                              {functions.returnLabel(key)}
                            </span>

                            {functions.keyIsDate(key) ? (
                              <span className="lead smaller-lead-ft--1">
                                {functions.formatDate(value, key)}
                              </span>
                            ) : (
                              <span className={`lead smaller-lead-ft--1`}>
                                {value}
                              </span>
                            )}
                          </Fragment>
                        </div>
                      );
                    })}

                  {Object.entries<any>(item)
                    .slice(4, Math.ceil(Object.entries<any>(item).length))
                    .map(([key, value], idx) => {
                      return (
                        <div
                          className="col-xs-12 col-sm-12 information-spec-cols"
                          key={idx}
                        >
                          <Fragment key={key}>
                            {key !== 'client_secret' &&
                            key !== 'client_user' ? (
                              <>
                                <span className="lead smaller-lead-ft--1">
                                  {functions.returnLabel(key)}
                                </span>

                                <span className={`lead smaller-lead-ft--1`}>
                                  {value}
                                </span>
                              </>
                            ) : (
                              <div className="container clipboard-align">
                                <div className="row clipboard-align">
                                  <div className="col-11 clipboard-align">
                                    <span className="lead smaller-lead-ft--1">
                                      {key === 'client_secret' ? (
                                        <span
                                          className={`badge badge-info ${
                                            tokenIsVisible
                                              ? 'spec-info-bdg'
                                              : 'hidden-by-default'
                                          }  with-pointer`}
                                          onClick={toggleShowToken}
                                        >
                                          {tokenIsVisible ? (
                                            <>
                                              <AiOutlineEyeInvisible id="eye-icon-outln" />
                                              Hide{' '}
                                            </>
                                          ) : (
                                            <>
                                              <AiOutlineEye id="eye-icon-outln-visible" />
                                              Show{' '}
                                            </>
                                          )}
                                          Client Secret
                                        </span>
                                      ) : (
                                        functions.returnLabel(key)
                                      )}
                                    </span>

                                    {key === 'client_secret' ? (
                                      tokenIsVisible ? (
                                        <span
                                          className={`lead smaller-lead-ft--1`}
                                        >
                                          {item?.client_secret}
                                        </span>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      <span
                                        className={`lead smaller-lead-ft--1`}
                                      >
                                        {value}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-1 clipboard-align">
                                    <TooltipView
                                      title={
                                        <GenTooltip
                                          title={`Grab the ${labels[key]}`}
                                          textOne={`Copy the ${labels[key]} to clipboard`}
                                        />
                                      }
                                    >
                                      <span>
                                        <button type="button">
                                          <FaRegCopy
                                            style={{
                                              color: '#000000',
                                              strokeWidth: '30',
                                              stroke: 'white',
                                            }}
                                            className="copy-to-clipboard-icon-clt"
                                            onClick={() =>
                                              copyToClipboard(value, key)
                                            }
                                          />
                                        </button>
                                      </span>
                                    </TooltipView>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetails;

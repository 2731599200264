import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CreateClientProps, ClientType } from './interface';
import { useHistory } from 'react-router-dom';
import { functions } from './helpers';
import config from './config';
import { Phone } from '../../common';
import validate from 'validate.js';
import {
  Modal,
  Notification,
  TooltipView,
  GenTooltip,
  Template,
  Label,
  IdInput,
  Input,
  Button,
  AutoSuggest,
} from 'business/modules/admin/common';
import { FaRegCopy } from 'react-icons/fa';
import { State, City } from 'country-state-city';
import ZipCodes from 'zipcodes';
import { GrCircleQuestion } from 'react-icons/gr';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill } from 'react-icons/ri';
import './styles.scss';

const COUNTRY_CODE = 'US';

const CreateClient: React.FC<CreateClientProps> = ({
  createAClient,
  handleModalState,
  modalMustClose,
  mandatoryFieldsMissing,
  handleMandatoryFields,
  generateSecretAndUser,
}) => {
  const history = useHistory();
  const [userQuits, setUserQuits] = useState(false);
  const [formKey, setFormKey] = useState('editClientFormKey');
  const [newClient, setNewClient] = useState<any>({});
  const [phone, setPhone] = useState<string>('');
  const [errors, setErrors]: any = useState(null);
  const [currentField, setCurrentField] = useState<string>('');
  const [formValues, setFormValues] = useState<any>(config.states.formValues);
  const [allStates, setAllStates]: any = useState([]);
  const [allCities, setAllCities]: any = useState([]);
  const [allCityZipCodes, setAllCityZipCodes]: any = useState([]);
  const [dataArrs, setDataArrs] = useState<any>(config.dataArrs);
  const [suggs, setSuggests] = useState<any>(config.suggestions);
  const [dataWasCleared, setDataWasCleared] = useState<boolean>(false);
  const [codes, setCodes] = useState({ 'user-id': '', 'token-id': '' });

  const labels: any = {
    'user-id': `Client User`,
    'token-id': `Client Secret`,
  };

  const copyContent = async (copyTxtToClip: any) => {
    try {
      await navigator.clipboard.writeText(copyTxtToClip);
    } catch (err) {
      //handle error copying to clipboard
    }
  };

  const copyToClipboard = (field: any) => {
    copyContent(codes[field.name as keyof typeof codes]);

    Notification({
      title: 'Success',
      message: `${labels[field.name]} copied to clipboard.`,
      type: 'success',
    });
  };

  const generateCodes = async () => {
    let response: any = await generateSecretAndUser();

    if (response?.statusCode === 200) {
      setCodes({
        ...codes,
        'user-id': response?.body?.client_user,
        'token-id': response?.body?.client_secret,
      });
    } else {
      Notification({
        title: 'Error!',
        message: config?.notifications?.code?.initCodesError,
        type: 'error',
      });
    }
  };

  const generateOneCode = async (typeOfCode: string) => {
    let response: any = await generateSecretAndUser();

    if (response?.statusCode === 200) {
      switch (typeOfCode) {
        case 'user-id':
          setCodes({
            ...codes,
            ['user-id']: response?.body?.client_user,
          });
          break;

        case 'token-id':
          setCodes({
            ...codes,
            ['token-id']: response?.body?.client_secret,
          });
          break;

        default:
          break;
      }
    } else {
      Notification({
        title: 'Error!',
        message: config?.notifications?.code?.error,
        type: 'error',
      });
    }
  };

  const registerClient = async () => {
    const data: ClientType = {
      email: newClient?.email,
      first_name: newClient?.first_name,
      last_name: newClient?.last_name,
      company: newClient?.company,
      phone: phone,
      redirectUrl: newClient?.redirectUrl,
      address: newClient?.address,
      state: formValues?.client_state?.name,
      city: formValues?.client_city?.name,
      zip_code: formValues?.client_zipcode?.value,
      client_user: codes?.['user-id'],
      client_secret: codes?.['token-id'],
    };

    let response: any = await createAClient(data);

    if (response?.statusCode === 200) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.create?.success,
        type: 'success',
      });
    } else {
      Notification({
        title: 'Warning!',
        message: config?.notifications?.create?.error,
        type: 'warning',
      });
    }

    setCurrentField('');
  };

  const cancelClientRegister = () => {
    setUserQuits(true);
    setNewClient({});
    setPhone('');
    setFormValues(config.states.formValues);
    setErrors(null);

    setSuggests(config.suggestions);

    setTimeout(() => {
      setUserQuits(false);
    }, 500);
    setFormKey('n-q-alts');

    setCurrentField('');
  };

  const handleCreateClient = async (event: any) => {
    let name: any = event.target.name;

    let currentFields: any = { [name]: event.target.value };

    let currentConstraints: any = {
      [name]: {
        ...config.constraints[name as keyof typeof config.constraints],
      },
    };

    setNewClient({
      ...newClient,
      [event.target.name]: event.target.value,
      phone: phone,
    });

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: '' };
        });
      })
      .catch((err: any) => {
        //allow empty redirect url field, since it is not required
        if (
          currentFields &&
          currentFields.hasOwnProperty('redirectUrl') &&
          currentFields?.redirectUrl.length === 0
        ) {
          setErrors((prevErrors: any) => {
            return { ...prevErrors, redirectUrl: '' };
          });
        } else {
          setErrors((prevErrors: any) => {
            return { ...prevErrors, [name]: err[name] };
          });
        }
      });
  };

  const handlePhoneEdit = async (value: any) => {
    let currentFields: any = { phone: value };
    let currentConstraints: any = {
      phone: {
        ...config.constraints['phone' as keyof typeof config.constraints],
      },
    };
    setPhone(value);

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: '' };
        });
      })
      .catch((err: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: err.phone };
        });
      });
  };

  const initAllStates = () => {
    let allStates: any = State.getStatesOfCountry(COUNTRY_CODE);
    allStates = functions.formatLocations(allStates);
    setAllStates(allStates);

    const stateNames = allStates.map((elem: any) => elem.name);
    setDataArrs({ ...dataArrs, states: stateNames });

    return allStates;
  };

  const prepareFields = () => {
    initAllStates();
  };

  const returnInputValue = (elem: any) => {
    return newClient[elem.name as keyof typeof newClient] || '';
  };

  const retrieveList = (field: string) => {
    switch (field) {
      case 'client_state':
        return allStates;
      case 'client_city':
        return allCities;
      case 'client_zipcode':
        return allCityZipCodes;
      default:
        return [];
    }
  };

  const grabSuggestion = (id: string, currentSuggestion: string) => {
    //mark which input is being selected, in order to handle the error display
    setCurrentField(id);
    setSuggests({ ...suggs, [id]: currentSuggestion });
  };

  const validateInputtedVals = (suggs: any) => {
    if (userQuits) {
      setErrors();
      return;
    }

    switch (currentField) {
      case 'client_state':
        if (dataArrs?.states?.includes(suggs?.client_state)) {
          setErrors({
            ...errors,
            client_state: '',
          });
        } else {
          setErrors({
            ...errors,
            client_state: 'Please, select a state from the list.',
          });
        }
        break;

      case 'client_city':
        if (dataArrs?.cities?.includes(suggs?.client_city)) {
          setErrors({
            ...errors,
            client_city: '',
          });
        } else {
          setErrors({
            ...errors,
            client_city: 'Please, select a city from the list.',
          });
        }
        break;
      case 'client_zipcode':
        if (dataArrs?.zips?.includes(suggs?.client_zipcode)) {
          setErrors({
            ...errors,
            client_zipcode: '',
          });
        } else {
          setErrors({
            ...errors,
            client_zipcode: 'Please, select a zipcode from the list.',
          });
        }
        break;

      default:
        break;
    }
  };

  const returnLocationValue = (formField: any) => {
    return formField === `client_state`
      ? formValues?.client_state?.name
      : formField === `client_city`
      ? formValues?.client_city?.name
      : formField === `client_zipcode`
      ? formValues?.client_zipcode?.value
      : ``;
  };

  const validateEmptyFieldsOrErrors = () => {
    let areThereEmptyFields: any;
    areThereEmptyFields = functions.preventEmptyData(newClient);

    let thereAreErrors: any = [];

    if (errors) {
      thereAreErrors = Object.values(errors).filter(
        (item: any) => item && item.length > 0,
      );
    }

    if (
      !areThereEmptyFields &&
      thereAreErrors.length === 0 &&
      !functions.areFieldsEmpty(formValues)
    ) {
      handleMandatoryFields('');
    } else if (
      areThereEmptyFields ||
      thereAreErrors.length > 0 ||
      functions.areFieldsEmpty(formValues)
    ) {
      handleMandatoryFields('mandatoryFieldsMissing');
    }
  };

  useEffect(() => {
    if (userQuits) {
      setFormKey('q-alts-cancel');
    }
  }, [userQuits]);

  useEffect(() => {
    prepareFields();
  }, []);

  useEffect(() => {
    if (formValues?.client_state?.label) {
      const { name, value } = formValues.client_state;
      let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
      tempCities = functions.formatLocations(tempCities);
      setAllCities([...tempCities]);

      //to posterior comparison - handle suggestion errs:
      const cityNames = tempCities.map((elem: any) => elem.name);

      setDataArrs({ ...dataArrs, cities: cityNames });
    }
  }, [formValues?.client_state?.label]);

  useEffect(() => {
    if (formValues?.client_city?.label) {
      const { name, stateCode, latitude, longitude } = formValues.client_city;
      let tempZipCodesByCoords: any = ZipCodes.lookupByCoords(
        latitude,
        longitude,
      );
      let tempZipCodes: any = ZipCodes.lookupByName(name, stateCode);
      tempZipCodes = functions.formatZipCodes(
        tempZipCodes && tempZipCodes.length > 0
          ? tempZipCodes
          : [tempZipCodesByCoords],
      );

      setAllCityZipCodes([...tempZipCodes]);

      //to posterior comparison:
      const zipNums = tempZipCodes.map((elem: any) => elem.zip);

      setDataArrs({ ...dataArrs, zips: zipNums });
    }
  }, [formValues?.client_city?.label]);

  useEffect(() => {
    //if the suggestion is empty , empty also the formvalue to display the error
    validateInputtedVals(suggs);
  }, [suggs]);

  useEffect(() => {
    validateEmptyFieldsOrErrors();
  }, [newClient, codes, formValues, errors]);

  useEffect(() => {
    if (userQuits) {
      setFormKey('q-alts');
    }
  }, [userQuits]);

  useEffect(() => {
    generateCodes();
  }, []);

  return (
    <Template
      id="create-dev-user"
      title={config?.userCreate?.mainTitle}
      goBack={() => {
        history.push('/admin/dashboard/clients');
      }}
    >
      <BreadcrumbsItem to={config?.strings?.breadcrumbCreate}>
        {config.strings.breadcrumbCreate}
      </BreadcrumbsItem>

      <div className={config.userCreate.class} id="create-dev-user--screen">
        <h4 className="centered">{config.userCreate.mainTitle} </h4>
        <p className="lead centered card-top-ft">{config.userCreate.pText}</p>
        <hr className="my-4" />
        <div className="container create-dev-user-cont">
          <div
            className="row d-flex justify-content-center"
            id="edit--clt-user"
          >
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5">
              {config.fields.slice(0, 6).map((field: any, index: number) => {
                return field.isPhone ? (
                  <div className="form-group" key={index}>
                    <Label className={`label-cmp-6`}>
                      {field?.label}{' '}
                      {field.showRequiredSign && (
                        <span className="required-ast">*</span>
                      )}
                    </Label>

                    <div className="input-group">
                      <div className="input--phone--field-client">
                        <Phone
                          key={`phone--${index}`}
                          handleChange={(value: any) => handlePhoneEdit(value)}
                          handleChangeError={() => {}}
                          name={field.name}
                          value={phone}
                          error={errors && errors[field.name]}
                          constraint={
                            config.constraints[
                              field.name as keyof typeof config.constraints
                            ]
                          }
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form-row-devclient" key={index}>
                    <Label className={`label-cmp-6`}>
                      {field.label}{' '}
                      {field.showRequiredSign && (
                        <span className="required-ast">*</span>
                      )}
                      {field.isCode && (
                        <TooltipView
                          title={
                            <GenTooltip
                              title={
                                config.strings.tooltipTitle[
                                  field.name as keyof typeof config.strings.tooltipTitle
                                ]
                              }
                              textOne={
                                config.strings.tooltipTxt[
                                  field.name as keyof typeof config.strings.tooltipTxt
                                ]
                              }
                            />
                          }
                        >
                          <span>
                            <GrCircleQuestion className="click-here-tooltip-client" />
                          </span>
                        </TooltipView>
                      )}
                      {field.isCode && (
                        <TooltipView
                          title={
                            <GenTooltip
                              title={`Grab the ${labels[field?.name]}`}
                              textOne={`Copy ${
                                labels[field?.name]
                              } to the clipboard`}
                            />
                          }
                        >
                          <span>
                            <button
                              type="button"
                              id="create-clt-fareg-btn"
                              onClick={() => copyToClipboard(field)}
                            >
                              <FaRegCopy
                                style={{
                                  color: '#000000',
                                  strokeWidth: '1',
                                  stroke: 'white',
                                }}
                                className="copy-to-clipboard-icon-clt fareg-i"
                              />
                            </button>
                          </span>
                        </TooltipView>
                      )}
                    </Label>

                    {field.isCode ? (
                      <div className="input-group">
                        <IdInput
                          className="full-width-gen-input"
                          index={index}
                          elem={field}
                          generatedCode={
                            codes[field.name as keyof typeof codes]
                          }
                          newCodeGenerator={
                            <div className="input-group-append">
                              <button
                                disabled={false}
                                className="btn btn-info spec-generate-id-dev"
                                type="button"
                                onClick={() => {
                                  generateOneCode(field.name);
                                }}
                              >
                                {
                                  config.genLabel[
                                    field.name as keyof typeof config.genLabel
                                  ]
                                }
                              </button>
                            </div>
                          }
                        />
                      </div>
                    ) : (
                      <div className="input-group">
                        <Input
                          type={field.type}
                          className={`${field.className}  ${
                            errors && errors[field?.name] ? `class-err` : ``
                          }`}
                          id={field.id}
                          name={field.name}
                          ariaDescribedBy={field.ariaDescribedBy}
                          placeholder={field.placeholder}
                          value={returnInputValue(field)}
                          onChange={(event: any) => handleCreateClient(event)}
                        />
                        {errors && (
                          <div className="input-error-message error-alert">
                            {errors[field?.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div
              className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5"
              key={formKey}
            >
              {config.fields.slice(6).map((field: any, index: number) => {
                return field.isPhone ? (
                  <div className="form-group" key={index}>
                    <Label className={`label-cmp-6`}>
                      {field?.label}{' '}
                      {field.showRequiredSign && (
                        <span className="required-ast">*</span>
                      )}
                    </Label>

                    <div className="input-group">
                      <div className="input--phone--field-client">
                        <Phone
                          key={`phone--${index}`}
                          handleChange={(value: any) => handlePhoneEdit(value)}
                          handleChangeError={() => {}}
                          name={field.name}
                          value={phone}
                          error={errors && errors[field.name]}
                          constraint={
                            config.constraints[
                              field.name as keyof typeof config.constraints
                            ]
                          }
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form-row-devclient" key={index}>
                    <Label className={`label-cmp-6`}>
                      {field.label}{' '}
                      {field.showRequiredSign && (
                        <span className="required-ast">*</span>
                      )}
                      {field.isCode && (
                        <TooltipView
                          title={
                            <GenTooltip
                              title={
                                config.strings.tooltipTitle[
                                  field.name as keyof typeof config.strings.tooltipTitle
                                ]
                              }
                              textOne={
                                config.strings.tooltipTxt[
                                  field.name as keyof typeof config.strings.tooltipTxt
                                ]
                              }
                            />
                          }
                        >
                          <span>
                            <GrCircleQuestion className="click-here-tooltip-client" />
                          </span>
                        </TooltipView>
                      )}
                    </Label>

                    {field.isCode ? (
                      <div className="input-group">
                        <IdInput
                          className="full-width-gen-input"
                          index={index}
                          elem={field}
                          generatedCode={
                            codes[field.name as keyof typeof codes]
                          }
                          newCodeGenerator={
                            <div className="input-group-append">
                              <button
                                disabled={false}
                                className="btn btn-info spec-generate-id-dev"
                                type="button"
                                onClick={() => {
                                  generateOneCode(field.name);
                                }}
                              >
                                {
                                  config.genLabel[
                                    field.name as keyof typeof config.genLabel
                                  ]
                                }
                              </button>
                            </div>
                          }
                        />
                      </div>
                    ) : (
                      <div className="input-group">
                        <Input
                          type={field.type}
                          className={`${field.className}  ${
                            errors && errors[field?.name] ? `class-err` : ``
                          }`}
                          id={field.id}
                          name={field.name}
                          ariaDescribedBy={field.ariaDescribedBy}
                          placeholder={field.placeholder}
                          value={returnInputValue(field)}
                          onChange={(event: any) => handleCreateClient(event)}
                        />
                        {errors && (
                          <div className="input-error-message error-alert">
                            {errors[field?.name]}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}

              {config.formConfig.map((formField: any, index: number) => {
                return (
                  <div className={formField.className} key={index}>
                    <div className="input-group">
                      <label className={`label-cmp-6`}>
                        {formField.label}{' '}
                        {formField.showRequiredSign && (
                          <span className="required-ast">*</span>
                        )}
                      </label>
                    </div>
                    {allStates.length > 0 && (
                      <div
                        className={
                          errors &&
                          errors.hasOwnProperty(formField?.field) &&
                          errors[formField?.field].length > 0
                            ? 'auto-sugg-errclass'
                            : ''
                        }
                      >
                        <AutoSuggest
                          grabSuggestion={grabSuggestion}
                          dataWasCleared={dataWasCleared}
                          id={formField.field}
                          handleChange={(newState: any) => {
                            setFormValues((prevState: any) => {
                              return {
                                ...prevState,
                                [formField.field]: { ...newState },
                              };
                            });

                            setDataWasCleared(false);
                          }}
                          data={retrieveList(formField.field)}
                          value={returnLocationValue(formField.field)}
                          {...formField.fields}
                        />
                      </div>
                    )}
                    <span className="input-error-message error-alert">
                      {errors && errors[formField.field]}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row d-flex justify-content-center save-dev-user-footer">
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-10">
              <Button
                onClick={cancelClientRegister}
                className="small-button nok-btn dismiss-btn float-right-btn"
                dataDismiss={`modal`}
                spanClass={`btn-child-ft`}
              >
                <HiOutlineXCircle className="cancel" /> CANCEL
              </Button>

              <Button
                className={`small-button ok-btn float-right-btn`}
                onClick={() => {}}
                dataToggle="modal"
                dataTarget={`#create-dev-user-modal`}
                type="button"
                spanClass={`btn-child-ft`}
                disabled={mandatoryFieldsMissing}
              >
                <RiSave3Fill className="edit" /> SAVE
              </Button>
            </div>
          </div>

          {mandatoryFieldsMissing && (
            <div className="row d-flex justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-10 alert--col1">
                <div
                  className="alert alert-info mt-2 alert-w alert-pl"
                  role="alert"
                >
                  <span className="required-ast">*</span>{' '}
                  {config.strings.warning}
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal
          config={config}
          bodyClass={functions.handleOpts(`create__user`, 'bodyClass')}
          className={`small-button ok`}
          onClick={registerClient}
          onClickCancel={cancelClientRegister}
          title={functions.handleOpts(`create__user`, `title`)}
          id={`create-dev-user-modal`}
          icon={functions.handleOpts(`create__user`, `icon`)}
          body={functions.handleOpts(`create__user`, 'bodies')}
          modalMustClose={modalMustClose}
          handleModalState={handleModalState}
          label={config.labels[`create__user` as keyof typeof config.labels]}
          hasConfirmBtn={true}
          sureToProceed={config.modalStrings.sureToProceed}
        />
      </div>
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminDevelopmentState } = state;

  return {
    modalMustClose: adminDevelopmentState.modalMustClose,
    mandatoryFieldsMissing: adminDevelopmentState.mandatoryFieldsMissing,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminDevelopmentState } = dispatch;
  return {
    handleMandatoryFields: adminDevelopmentState.handleMandatoryFields,
    createAClient: adminDevelopmentState.createAClient,
    handleModalState: adminDevelopmentState.handleModalState,
    generateSecretAndUser: adminDevelopmentState.generateSecretAndUser,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateClient);

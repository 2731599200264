import React, { createContext, useCallback, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  User,
  AdminUser,
  AuthState,
  AccountType,
  SignInCredentials,
  AuthContextData,
  SignInCodeCredentials,
  UserState,
  AdminUserState,
} from '../config/types';
import { useHistory } from 'react-router-dom';
import { config } from '../config/config';
import { ConnectionBroker } from '../../../../core';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);
const STORAGE_STACK: string = '@air-stack-charts:';
const USER_LABEL: string = '@aircyler:user';
const ACCOUNT_TYPE: string = '@aircyler:account';
const TOKEN_LABEL: string = '@aircyler:token';
const IS_SIGNING_IN: string = '@aircyler:is-signing-in';
const ADMIN_USER_LABEL: string = '@aircyler:admin-user';
const ADMIN_TOKEN_LABEL: string = '@aircyler:admin-token';


const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataToken, setDataToken] = useState<AuthState>(() => {
    const token: any = localStorage.getItem(TOKEN_LABEL);
    if (token !== 'undefined') {
      return { token };
    }
    return {} as AuthState;
  });
  const [dataUser, setDataUser] = useState<UserState>(() => {
    const user: any = localStorage.getItem(USER_LABEL);
    if (user !== 'undefined') {
      return { user: JSON.parse(user) };
    }
    return {} as UserState;
  });
  const [accountType, setAccountType] = useState<AccountType>(() => {
    const accountType: any = localStorage.getItem(ACCOUNT_TYPE);
    if (accountType !== 'undefined') {
      return accountType;
    }
    return '';
  });

  const [dataAdminToken, setDataAdminToken] = useState<AuthState>(() => {
    const token: any = localStorage.getItem(ADMIN_TOKEN_LABEL);
    if (token !== 'undefined') {
      return { token };
    }
    return {} as AuthState;
  });
  const [dataAdminUser, setDataAdminUser] = useState<AdminUserState>(() => {
    const user: any = localStorage.getItem(ADMIN_USER_LABEL);
    if (user !== 'undefined') {
      return { user: JSON.parse(user) };
    }
    return {} as AdminUserState;
  });


  const signIn = useCallback(
    async (loginData: SignInCredentials) => {
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'session.auth',
        loginData,
        null,
      );
      localStorage.setItem(IS_SIGNING_IN, JSON.stringify({ sign: true }));

      if (config.loginTwoFactorAuthentication) {
        if (response.result) {
          history.push('/login/code');
        }
      } else {
        if (response.result) {
          const { token } = response.body;
          storeSessionToken(token);
          let responseProfile = await ConnectionBroker.fetchAPI(
            'jitBE',
            'profile.show',
            {},
            null,
          );
          const {
            last_name,
            first_name,
            picture,
            details,
            account_details,
          } = responseProfile?.body || {};
          const { installer }: any = account_details || {};
          const accType: any = account_details?.type || null;

          storeUserProfile({
            user: { last_name, first_name, picture },
          });
          storeAccountType(
            installer && accType === 'installer' ? accType : 'customer'
          );

          if(installer && accType === 'installer'){
            history.push('/installer');
          }else{
            history.push('/dashboard');
          }

        }
      }
    },
    [history],
  );


  const signInAdmin = useCallback(
    async (loginData: SignInCredentials) => {
      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'session.authAdmin',
        loginData,
        null,
      );

      if (config.loginTwoFactorAuthentication) {
        if (response.result) {
          history.push('/login/code');
        }
      } else {
        if (response.result) {
          const { token, id } = response.body;
          storeAdminSessionToken(token);
          let responseProfile = await ConnectionBroker.fetchAdminAPI(
            'jitBE',
            'adminProfile.show',
            null,
            { id: id },
          );
          const { username, email }: any = responseProfile?.body?.data || {};

          storeAdminUserProfile({
            user: { first_name: username, email, isAdmin: true },
          });
          history.push('/admin/dashboard');
        }
      }
    },
    [history],
  );

  const storeSessionToken = async (token: any) => {
    localStorage.setItem(TOKEN_LABEL, token);
    setDataToken({ token });
  };

  const storeUserProfile = async (user: any) => {
    localStorage.setItem(USER_LABEL, JSON.stringify(user));
    setDataUser({ user });
  };

  const storeAccountType = async (account: any) => {
    localStorage.setItem(ACCOUNT_TYPE, account);
    setAccountType(account);
  };


  const storeAdminSessionToken = async (token: any) => {
    localStorage.setItem(ADMIN_TOKEN_LABEL, token);
    setDataAdminToken({ token });
  };

  const storeAdminUserProfile = async (user: any) => {
    localStorage.setItem(ADMIN_USER_LABEL, JSON.stringify(user));
    setDataAdminUser({ user });
  };


  const signInCode = useCallback(
    async (loginCodeData: SignInCodeCredentials) => {
      /*
      let response: any;
      response = await loginCode(loginCodeData);
      const { token, user } = response.data;
      storeSession(token, user);
      */
    },
    [],
  );

  const signOut = useCallback(() => {
    dispatch.profile.resetProfile();
    dispatch.awair.resetUserConnection();
    dispatch.airthings.resetUserConnection();
    dispatch.env.resetUserInit();
    dispatch.env.resetVentStack();
    dispatch.devices.reset();
    dispatch.alerts.reset();    
    localStorage.removeItem(TOKEN_LABEL);
    localStorage.removeItem(USER_LABEL);
    localStorage.removeItem(STORAGE_STACK);
    setDataToken({} as AuthState);
    setDataUser({} as UserState);
  }, []);


  const signOutAdmin = useCallback(() => {
    localStorage.removeItem(ADMIN_TOKEN_LABEL);
    localStorage.removeItem(ADMIN_USER_LABEL);

    setDataAdminToken({} as AuthState);
    setDataAdminUser({} as AdminUserState);
  }, []);


  const updateUser = useCallback(
    (user: User) => {
      localStorage.setItem(USER_LABEL, JSON.stringify(user));

      setDataUser({
        user,
      });
    },
    [setDataUser],
  );


  const updateAdminUser = useCallback(
    (user: AdminUser) => {
      localStorage.setItem(ADMIN_USER_LABEL, JSON.stringify(user));

      setDataAdminUser({ user });
    },
    [setDataAdminUser],
  );


  return (
    <AuthContext.Provider
      value={{
        user: dataUser.user,
        adminUser: dataAdminUser.user,
        accountType,
        signIn,
        signInAdmin,
        signOut,
        signOutAdmin,
        updateUser,
        updateAdminUser,
        signInCode,
        token: dataToken,
        adminToken: dataAdminToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };

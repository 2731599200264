import { Keys, RelevantProps, SStrings } from './interface';

export const keys: Keys = {
  uuid: 'UUID',
  start: 'Start',
  end: 'End',
  sensors: 'Sensors',
  units: 'Units',
  datapoints: 'Datapoints',
  station: 'Station',
  distanceToDeviceKm: 'Distance to the device (km)',
  info: 'Information',
  connectivity: 'Connectivity status',
  fwUpgradeStatus: 'Firmware Upgrade status',
  sensorsStatus: 'Sensor status',
  deviceEnvironment: 'Device environment',
  battery: 'Battery',
  local: 'Local',
  city: 'City',
  state: 'State',
  country: 'Country',
  deviceType: 'Device type',
  modelVersion: 'Model version',
  isUpToDate: 'Up to date',
  firmwareVersions: 'Firmware versions',
  sensorsStats: 'Sensors stats',
  firmwareType: 'Firmware type',
  version: 'Version',
  latest: 'Latest',
  localDay: 'Local day',
  stats: 'Stats',
  hum: 'Humidity',
  tmp: 'Temperature',
  co2: 'CO2',
  voc: 'VOC',
  pm: 'PM',
  min: 'Minimum',
  max: 'Maximum',
  mean: 'Mean',
  p90: 'P90',
  count: 'Count',
  p10: 'P10',
  time: 'Time',
  data: 'Data',
  sensor: 'Sensor',
  unit: 'Unit',
  value: 'Value',
  nearestOutdoorStation: 'Nearest Outdoor Station',
  distanceToLocationInKm: 'Distance to location (km)',
  lat: 'Latitude',
  lon: 'Longitude',
};

export const relevantProps: RelevantProps = {
  fwUpgradeStatus: ['isUpToDate', 'firmwareVersions'],
  station: ['city', 'country', 'state', 'station'],
  info: ['deviceType', 'modelVersion'],
  sensorsStatus: ['sensorsStats'],
  deviceEnvironment: [
    'distanceToLocationInKm',
    'nearestOutdoorStation',
    'lat',
    'lon',
  ],
};

export const strings: SStrings = {
  firstSection: 'Datapoint info',
  secondSection: 'Air quality',
  thirdSection: "Sensor's health values",
};

import React from 'react';

interface DataProps {
  cnt: string;
  row: string;
  col1: string;
  col2: string;
  firstContent: any;
  secondContent: any;
  optionalEl?: any;
}

const Data: React.FC<DataProps> = ({
  cnt,
  row,
  col1,
  col2,
  firstContent,
  secondContent,
  optionalEl,
}) => {
  return (
    <div className={cnt}>
      <div className={row}>
        <div className={col1}>{firstContent}</div>
        <div className={col2}>{secondContent}</div>
      </div>
      {optionalEl}
    </div>
  );
};

export default Data;

import React, { useEffect, useState } from 'react';
import config from './config';
import { ClientsListProps } from './interface';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserDetails, ViewUserDetails } from './components';
import {
  List,
  Notification,
  Modal,
  Template,
} from 'business/modules/admin/common';
import { MdDelete } from 'react-icons/md';
import { RxMagnifyingGlass } from 'react-icons/rx';
import { HiOutlinePlus } from 'react-icons/hi';
import { BsCodeSlash } from 'react-icons/bs';
import { MdErrorOutline } from 'react-icons/md';
import './styles.scss';

const ClientsList: React.FC<ClientsListProps> = ({
  setCurrentDevUser,
  currentDevUser,
  orders,
  devsCounter,
  devsIntendedCounter,
  updateOrderState,
  orderedList,
  handleDevsListCounterAndOrder,
  handleModalState,
  modalMustClose,
  deleteAClient,
  getClientsList,
  loading,
}) => {
  const history = useHistory();
  const [current, setCurrent] = useState('');
  const [devUsers, setDevUsers]: any = useState([]);
  const [initList, setInitList]: any = useState([]);
  const [loadedDevs, setLoadedDevs] = useState<boolean>(false);
  const [unorderedDefault, setUnorderedDefault] = useState([]);
  const [loadingErrors, setLoadingErrors] = useState({
    clientsList: '',
  });

  const incremented: any = {
    date: devsIntendedCounter.date + 1,
    company: devsIntendedCounter.company + 1,
    email: devsIntendedCounter.email + 1,
    client: devsIntendedCounter.client + 1,
  };

  const getTheClients = async () => {
    loading.start('Loading Clients List...');

    let response: any = await getClientsList();

    if (response?.statusCode === 200) {
      setDevUsers(response?.body);
      setInitList(response?.body);
      setLoadedDevs(true);
    } else {
      setLoadingErrors({
        ...loadingErrors,
        clientsList:
          'Something went wrong while loading the clients list. Please, try again later!',
      });
    }
    loading.stop();
  };

  const handleView = (item: any) => {
    history.push(`/admin/dashboard/clients/client/edit`);
  };

  const registerClient = () => {
    history.push(`/admin/dashboard/clients/client/add`);
  };

  const deleteClient = async () => {
    /**
   * Important: this is not working yet because of the Mongo Error: result after submitting delete req:
   *
   * {
    "statusCode": 500,
    "result": false,
    "body": {
        "error": {
            "ok": 0,
            "code": 11000,
            "codeName": "DuplicateKey",
            "keyPattern": {
                "email": 1
            },
            "keyValue": {
                "email": ""
            },
            "$clusterTime": {
                "clusterTime": "7439040810173071365",
                "signature": {
                    "hash": "COlPTO2WYG6m23iHRci+qeRekbc=",
                    "keyId": "7377409876311408642"
                }
            },
            "operationTime": "7439040810173071363",
            "name": "MongoError"
        }
    }
}
   *
   *
   *
   *
   */

    let response: any = await deleteAClient({
      client_id: currentDevUser?.client_id,
    });

    if (response?.statusCode === 200) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.delete?.success,
        type: 'success',
      });
    } else {
      Notification({
        title: 'Warning!',
        message: config?.notifications?.delete?.error,
        type: 'warning',
      });
    }

    await getClientsList();
  };

  const retrieveColValue = (item: any, position: string) => {
    let createdAt: any = item?.createdAt !== '' ? new Date(item.createdAt) : '';

    switch (position) {
      case '1':
        return (
          <>
            {createdAt !== '' ? moment(createdAt).year() : ''},{' '}
            {moment(createdAt).format('MMMM')}
          </>
        );

      case '2':
        return <div className="truncate-str-dev-token">{item?.company}</div>;

      case '3':
        return <>{item.email}</>;

      case '4':
        return <>{item.client_user}</>;

      default:
        return ``;
    }
  };

  const filterItemObject = (clientItem: any) => {
    let filteredObject: any = {};

    if (Object.keys(clientItem).length > 0) {
      let filteredPropsArray = Object.entries(clientItem).filter(
        (item: any, val: any) => {
          return (
            item[0] !== 'details' &&
            item[0] !== '_id' &&
            item[0] !== 'updatedAt' &&
            item[0] !== '__v' &&
            item[0] !== 'redirect_url' //while not having none
          );
        },
      );
      filteredPropsArray.forEach((item: any) => {
        filteredObject[item[0]] = item[1];
      });
    }
    return filteredObject;
  };

  const returnAccordionDetails = (item: any) => {
    return (
      <UserDetails
        config={config}
        item={filterItemObject(item)}
        details={item?.details}
      />
    );
  };

  useEffect(() => {
    if (orders.orderByDate) {
      setCurrent(`date`);
    } else if (orders.orderByCompany) {
      setCurrent(`company`);
    } else if (orders.orderByEmail) {
      setCurrent(`email`);
    } else if (orders.orderByClient) {
      setCurrent(`client`);
    } else if (orderedList && loadedDevs && unorderedDefault.length > 0) {
      setCurrent(``);
    }
  }, [orders, devsIntendedCounter, devsCounter, orderedList]);

  useEffect(() => {
    getTheClients();
  }, []);

  //preserves the default order
  useEffect(() => {
    if (initList && initList.length > 0) {
      let savedDefaultArr: any = [];
      initList.forEach((item: any) => {
        savedDefaultArr.push(item);
      });
      setUnorderedDefault(savedDefaultArr);
    }
  }, [initList]);

  return (
    <Template
      id="development-list"
      title={config.strings.title1}
      hasRightBtn={true}
      fun={registerClient}
      icon={<HiOutlinePlus className="svg-refresh" />}
    >
      {loadingErrors &&
      loadingErrors?.clientsList &&
      loadingErrors?.clientsList?.length > 0 ? (
        <div id="errors-display--4">
          <div className="alert alert-info" role="alert">
            <MdErrorOutline className="alert-icon" />
            <span className="lead err-string">
              {loadingErrors?.clientsList}
            </span>
          </div>
        </div>
      ) : (
        <List
          rowClasses={config.rowClasses}
          icon={<BsCodeSlash className="list--icon" />}
          id="development-accordion"
          list={devUsers}
          loaded={loadedDevs}
          itemsPerPage={10}
          manageListOrder={handleDevsListCounterAndOrder}
          orderedList={orderedList}
          orders={orders}
          sectionCounter={devsIntendedCounter}
          sectionCount={devsCounter}
          updateOrderState={updateOrderState}
          incremented={incremented}
          current={current}
          unorderedDefault={unorderedDefault}
          handles={{
            handleView,
          }}
          config={config}
          secondFun={(item: any) => {
            handleView(item);
          }}
          fourthFunction={(item: any) => {}}
          setItem={(item: any) => {
            setCurrentDevUser(item);
          }}
          retrieveColValue={retrieveColValue}
          returnAccordionDetails={returnAccordionDetails}
        />
      )}
      <Modal
        config={config}
        id={`view-devuser-modal-id`}
        title={config.modal.view.title}
        icon={<RxMagnifyingGlass className="edit" />}
        body={
          <ViewUserDetails
            id="the-card-vcd-2"
            item={currentDevUser}
            config={config}
          />
        }
        onClick={() => {}}
        modalMustClose={false}
        handleModalState={() => {}}
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={``}
        hasConfirmBtn={false}
      />

      <Modal
        config={config}
        id={`delete-dev-user-modal-id`}
        title={config.modal.delete.title}
        icon={<MdDelete className="edit" />}
        body={config?.modalStrings?.aboutToDel}
        onClick={deleteClient}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        label={config?.modalStrings?.labelOK}
        className={`small-button nok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={config?.modalStrings?.sureToProceed}
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminDevelopmentState } = state;

  return {
    currentDevUser: adminDevelopmentState.currentDevUser,
    orders: adminDevelopmentState.orders,
    devsCounter: adminDevelopmentState.devsCounter,
    devsIntendedCounter: adminDevelopmentState.devsIntendedCounter,
    orderedList: adminDevelopmentState.orderedList,
    modalMustClose: adminDevelopmentState.modalMustClose,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, adminDevelopmentState } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getClientsList: adminDevelopmentState.getClientsList,
    deleteAClient: adminDevelopmentState.deleteAClient,
    handleModalState: adminDevelopmentState.handleModalState,
    updateOrderState: adminDevelopmentState.updateOrderState,
    setCurrentDevUser: adminDevelopmentState.setCurrentDevUser,
    handleDevsListCounterAndOrder:
      adminDevelopmentState.handleDevsListCounterAndOrder,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsList);

import React, { Fragment } from 'react';
import { Labels, Helpers } from '../../interface';
import './styles.scss';

interface DevDetailsProps {
  value: any;
  labels: Labels;
  helpers: Helpers;
}

const DevDetails: React.FC<DevDetailsProps> = ({ value, labels, helpers }) => {
  const handleValue = (v: any) => {
    return !v ? (
      <span className="lead span-key-040 pdg">No values</span>
    ) : typeof v === 'boolean' ? (
      v.toString()
    ) : typeof v === 'string' || typeof v === 'number' ? (
      v
    ) : //it seems that there are no array or object values
    helpers.isOb(v) || Array.isArray(v) ? (
      JSON.stringify(v)
    ) : (
      JSON.stringify(v)
    );
  };

  return (
    <div className="container pdg dev-details-result-modal">
      <div className="row mlmr0   mt-3 mb-2">
        <div className="lead lead--smaller ftw600 location-label">
          Device detailed information
        </div>
        <div className="row-sep2"></div>
      </div>

      <div className="row mlmr0">
        {helpers.isOb(value) &&
          Object.entries(value).map(([k, v]: [string, any], idx: number) => {
            return (
              <div className="container pdg" key={idx}>
                <div className="row mlmr0  ">
                  <div
                    className={`col-${
                      k === 'settings' ? '12' : '6'
                    } pdg item-padding`}
                  >
                    {k === 'settings' ? (
                      <div className="inline-label lead lead-sm label-result-sp bolder location-label">
                        {labels[k]}
                      </div>
                    ) : (
                      <span
                        className={`inline-label lead lead-sm label-result-sp bolder ${
                          k === 'settings' ? 'underline-label' : ''
                        }`}
                      >
                        {labels[k]}
                      </span>
                    )}
                  </div>

                  {helpers.valIsBool(v) ? (
                    <span className="lead span-key-040 pdg">
                      {v.toString()}
                    </span>
                  ) : helpers.valueIsStrOrNum(v) ? (
                    <span className="lead span-key-040 pdg">{v}</span>
                  ) : !v ? (
                    <span className="lead span-key-040 pdg">No values</span>
                  ) : helpers.isOb(v) ? (
                    <div className="container pdg">
                      <div className="row-sep1"></div>
                      {Object.entries(v).map(
                        ([_key, _value]: [string, any], index: number) => {
                          return (
                            <div className="row mlmr0 item-padding" key={index}>
                              <div
                                className={`col-${
                                  _key === 'conf' ? '12' : '8'
                                } pdg item-padding`}
                              >
                                {_key === 'conf' ? (
                                  <div className="inline-label lead lead-sm label-result-sp bolder location-label">
                                    {labels[_key]}
                                  </div>
                                ) : (
                                  <span className="inline-label lead lead-sm label-result-sp bolder">
                                    {labels[_key]}
                                  </span>
                                )}
                              </div>

                              <div className="col pdg item-padding right-align">
                                {helpers.valIsBool(_value) ? (
                                  <span className="lead span-key-040 pdg">
                                    {_value.toString()}
                                  </span>
                                ) : helpers.valueIsStrOrNum(_value) ? (
                                  <span className="lead span-key-040 pdg">
                                    {_value}
                                  </span>
                                ) : !_value ||
                                  (_value &&
                                    typeof _value === 'string' &&
                                    _value.length === 0) ? (
                                  <span className="lead span-key-040 pdg">
                                    No values
                                  </span>
                                ) : Array.isArray(_value) ? (
                                  <span>{JSON.stringify(value)}</span>
                                ) : helpers.isOb(_value) ? (
                                  <div className="container pdg">
                                    <div className="row-sep1"></div>
                                    {Object.entries(_value).map(
                                      (
                                        [k, item]: [string, any],
                                        idx: number,
                                      ) => {
                                        return (
                                          <div
                                            className={`row mlmr0 item-padding`}
                                            key={idx}
                                          >
                                            <div
                                              className={`col-${
                                                k === 'cdvs' ? '12' : '8'
                                              } pdg item-padding`}
                                            >
                                              {k === 'cdvs' ? (
                                                <h4 className="slategrey-label">
                                                  {labels[k]}
                                                </h4>
                                              ) : (
                                                <span className="inline-label lead lead-sm label-result-sp bolder">
                                                  {labels[k]}
                                                </span>
                                              )}
                                            </div>

                                            <div
                                              className={`col-${
                                                k === 'cdvs'
                                                  ? '12'
                                                  : '4 right-align'
                                              } pdg item-padding`}
                                            >
                                              {helpers.valueIsStrOrNum(item) ? (
                                                <span className="lead span-key-040 pdg">
                                                  {item}
                                                </span>
                                              ) : (Array.isArray(item) &&
                                                  item?.length === 0) ||
                                                !item ? (
                                                k === 'cdvs' ? (
                                                  <div className="no-cdvs-alert">
                                                    <span className="lead span-key-040 pdg">
                                                      No values to display.
                                                    </span>
                                                  </div>
                                                ) : (
                                                  <span className="lead span-key-040 pdg">
                                                    No values
                                                  </span>
                                                )
                                              ) : Array.isArray(item) &&
                                                item?.length > 0 &&
                                                k === 'cdvs' ? (
                                                <div className="row">
                                                  {Object.entries(item).map(
                                                    (
                                                      [_key, _val]: [
                                                        string,
                                                        any,
                                                      ],
                                                      i: number,
                                                    ) => {
                                                      return (
                                                        <Fragment key={i}>
                                                          {_val &&
                                                          helpers.isOb(_val) &&
                                                          _val.hasOwnProperty(
                                                            'cdid',
                                                          ) ? (
                                                            <h6
                                                              className={`${
                                                                i === 0
                                                                  ? `grey-h6-less-top-space`
                                                                  : `grey-h6 `
                                                              }`}
                                                            >
                                                              {_val?.cdid}
                                                            </h6>
                                                          ) : (
                                                            <h6 className="grey-h6">
                                                              {`Device # ${i}`}
                                                            </h6>
                                                          )}

                                                          <div className="row-sep1"></div>
                                                          {Object.entries(
                                                            _val,
                                                          ).map(
                                                            (
                                                              [k, v]: [
                                                                string,
                                                                any,
                                                              ],
                                                              idx: number,
                                                            ) => {
                                                              return (
                                                                <div
                                                                  key={idx}
                                                                  className="container pdg"
                                                                >
                                                                  <div className="row mlmr0 item-padding">
                                                                    <div className="col pdg center-cnts item-padding">
                                                                      <span className="inline-label lead lead-sm label-result-sp bolder">
                                                                        {
                                                                          labels[
                                                                            k
                                                                          ]
                                                                        }
                                                                      </span>
                                                                    </div>
                                                                    <div className="col-auto pdg item-padding">
                                                                      <span className="lead span-key-040 pdg">
                                                                        {handleValue(
                                                                          v,
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              );
                                                            },
                                                          )}
                                                          <div className="row-sep"></div>
                                                        </Fragment>
                                                      );
                                                    },
                                                  )}
                                                </div>
                                              ) : (
                                                <span className="lead span-key-040 pdg">
                                                  {JSON.stringify(item)}
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      },
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          );
                        },
                      )}
                    </div>
                  ) : Array.isArray(v) ? (
                    <> </>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default DevDetails;

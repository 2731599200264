import _ from 'lodash';
import moment from 'moment';
import config from '../config';


const TABLE_COLUNMS: any = [...config.table.columnsKeys];
const SLICE_VALUE: number = 2;

const csvConverterUtil: any = {
  defineCsvRequestParams: (page: number, size: number) => {
    const realPage: number = page + 1;
    const maxPeriods: number = 24 - realPage;

    return {
      _page: realPage,
      _periods: maxPeriods,
    };
  },
  keysMapper: (prop: string) => {
    const foundedItem: any = TABLE_COLUNMS.find(
      (row: any) => row.field === prop
    );

    if(foundedItem){
      const { title } = foundedItem || {};

      return title;
    }

    return;
  },
  convertTitleKeys: (_data: any) => {
    const allKeys: any = Object.keys(_data[0]);
    let mappedKeys: any = _.cloneDeep(allKeys);

    if(allKeys && Array.isArray(allKeys) && allKeys.length > 0){
      mappedKeys = allKeys.map((key: string) => {
        let mappedKey: any = csvConverterUtil.keysMapper(key);

        return mappedKey;
      });

      mappedKeys = mappedKeys.slice(0, mappedKeys.length - SLICE_VALUE);

      mappedKeys = mappedKeys.filter(
        (item: string) => !!item && item !== ''
      );
    }

    return mappedKeys ? mappedKeys : allKeys;
  },
  convertDataValues: (_data: any) => {
    return _data.map((item: any) => {
      return Object.values(item).slice(0, (Object.values(item).length - SLICE_VALUE));
    });
  },
  convertCsvContent: (titles: any, content: any) => {
    let csvContent: string = '';
    const allData: any = [titles].concat(content);

    allData.forEach((row: any) => {
      csvContent += row.join(',') + '\n'
    });

    return csvContent;
  },
  formatCsvdata: (csvContent: any) => {
    const blob: any = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
    const objUrl: any = URL.createObjectURL(blob)

    return objUrl;
  },
  downloadCsvFile: (urlData: any, name: string) => {
    const strDate: any = moment().format('MM-DD-YY');
    const fileName: string = `${name && name !== '' ? name : 'AirCycler'}-Data-sample_(${strDate}).csv`;
    const link = document.createElement('a');
    link.setAttribute('href', urlData);
    link.setAttribute('download', fileName);
    link.click();
  },
  exportCsv: (columns: any, data: any, devName: string) => {
    if(data && Array.isArray(data) && data.length > 0){
      let titleKeys: any = csvConverterUtil.convertTitleKeys(data);
      let keysValues: any = csvConverterUtil.convertDataValues(data);
      const csvContent: any = csvConverterUtil.convertCsvContent(titleKeys, keysValues);
      const dataUrl: any = csvConverterUtil.formatCsvdata(csvContent);

      csvConverterUtil.downloadCsvFile(dataUrl, devName);
    }
  },
};

export default csvConverterUtil;

import React from 'react';
import './styles.scss';


interface TableHeaderProps {
  columns: any;
}

const TableHeader: React.FC<TableHeaderProps> = ({ columns, ...props }) => {

  return (
    <div className="th-helper">
      <div className="th-helper-header th-date">{columns[0].title}</div>
      <div className="th-helper-header th-time">{columns[1].title}</div>
      <div className="th-helper-header th-cf">{columns[4].title}</div>
      <div className="th-helper-header th-damper">{columns[5].title}</div>
      <div className="th-helper-header th-exhaust">{columns[6].title}</div>
      <div className="th-helper-header th-fair">{columns[9].title}</div>
      <div className="th-helper-header th-thermostat">{columns[10].title}</div>
      <div className="th-helper-header th-outside">{columns[11].title}</div>
      <div className="th-helper-header th-indoor">{columns[15].title}</div>
      <div className="th-helper-header th-event">{columns[21]?.title}</div>

      <div className="th-helper-header th-current">{columns[2]?.title}</div>
      <div className="th-helper-header th-left">{columns[3]?.title}</div>
      <div className="th-helper-header th-state">{columns[7]?.title}</div>
      <div className="th-helper-header th-light">{columns[8]?.title}</div>
      <div className="th-helper-header th-temp">{columns[12]?.title}</div>
      <div className="th-helper-header th-hum">{columns[13]?.title}</div>
      <div className="th-helper-header th-aqi">{columns[14]?.title}</div>
      <div className="th-helper-header th-pm25">{columns[16]?.title}</div>
      <div className="th-helper-header th-co2">{columns[17]?.title}</div>
      <div className="th-helper-header th-voc">{columns[18]?.title}</div>
      <div className="th-helper-header th-temp_in">{columns[19]?.title}</div>
      <div className="th-helper-header th-hum_in">{columns[20]?.title}</div>
    </div>
  );
};

export default TableHeader;

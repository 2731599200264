import React from 'react';
import { IdInputProps } from './interface';
import { Input } from 'business/modules/admin/common';
import './styles.scss';

const IdInput: React.FC<IdInputProps> = ({
  index,
  elem,
  generatedCode,
  newCodeGenerator,
}) => {
  return (
    <div className="form-group id-input-elem" key={index}>
      <div className="input-group">
        <Input
          type={elem.type}
          className={elem.className}
          id={elem.id}
          name={elem.name}
          ariaDescribedBy={elem.ariaDescribedBy}
          placeholder={elem.placeholder}
          children={elem.label}
          value={generatedCode || ''}
          disabled={true}
        />

        {newCodeGenerator}
      </div>
    </div>
  );
};

export default IdInput;

import React, { useState, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { functions } from './helpers';
import { AddInstallerProps } from './interface';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill, RiEdit2Line } from 'react-icons/ri';
import { FaRegCopy } from 'react-icons/fa';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { State, City } from 'country-state-city';
import ZipCodes from 'zipcodes';
import {
  Template,
  Button,
  Input,
  Phone,
  Label,
  Modal,
  AutoSuggest,
  Notification,
  TooltipView,
  GenTooltip,
} from 'business/modules/admin/common';
import './styles.scss';

const COUNTRY_CODE = 'US';

const AddInstaller: React.FC<AddInstallerProps> = ({
  clearNewInstaller,
  compItem,
  setUserIsTyping,
  saveCompItemGlobally,
  newInstaller,
  saveNewInstaller,
  setError,
  mandatoryFieldsMissing,
}) => {
  const history = useHistory();
  const [autosuggestKey, setKey] = useState('init');
  const [userCleared, setUserCleared] = useState<boolean>(false);
  const [errors, setErrors]: any = useState(null);
  const [currentField, setCurrentField] = useState('');
  const [formValues, setFormValues] = useState<any>(config.states.formValues);
  const [allStates, setAllStates]: any = useState([]);
  const [allCities, setAllCities]: any = useState([]);
  const [allCityZipCodes, setAllCityZipCodes]: any = useState([]);
  const [dataArrs, setDataArrs] = useState<any>({
    states: [],
    cities: [],
    zips: [],
  });
  const [suggs, setSuggests] = useState<any>({
    installer_state: '',
    installer_city: '',
    installer_zipcode: '',
  });

  const copyContent = async (copyTxtToClip: any) => {
    try {
      await navigator.clipboard.writeText(copyTxtToClip);
    } catch (err) {
      //handle error copying to clipboard
    }
  };

  const copyToClipboard = (code: any) => {
    copyContent(code);

    Notification({
      title: 'Success',
      message: `Company code copied to clipboard.`,
      type: 'success',
    });
  };

  const resetKey = (newKey: string) => {
    setKey(newKey);
  };

  const cancel = () => {
    setUserCleared(true);

    setTimeout(() => {
      setUserCleared(false);
    }, 1000);
  };

  const saveInstaller = async () => {};

  const handlePhoneChange = (value: any, country: any, formattedValue: any) => {
    setErrors({ ...errors, phone: '' });
    saveNewInstaller({ ...newInstaller, phone: '+' + value });

    if (!isValidPhoneNumber('+' + value)) {
      setErrors({ ...errors, phone: 'Invalid phone number.' });
    } else {
      setErrors({ ...errors, phone: '' });
    }
  };

  const getUserInput = (event: any) => {
    setUserIsTyping(true);
    let name: string = event.target.name;
    let value: any = event.target.value;

    let validate = functions.handleErrors(name, value);

    setErrors({ ...errors, [name]: validate });

    saveNewInstaller({
      ...newInstaller,
      [name]: value,
    });
  };

  const returnVal = (elem: any) => {
    return newInstaller[elem.name as keyof typeof newInstaller];
  };

  const preventEmptyData = (newInstaller: any) => {
    let emptyFields: any = [];

    if (newInstaller) {
      Object.entries<any>(newInstaller).map(([key, val], i) => {
        if (
          key !== 'code' &&
          typeof val === 'string' &&
          val.trim().length === 0
        ) {
          emptyFields.push(key);
        } else if (key !== 'code' && typeof val !== 'string') {
        }
      });

      if (emptyFields.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const validateEmptyFieldsOrErrors = () => {
    let areThereEmptyFields: any;
    areThereEmptyFields = preventEmptyData(newInstaller);

    let thereAreErrors: any = [];

    if (errors) {
      thereAreErrors = Object.values(errors).filter(
        (item: any) => item.length > 0,
      );
    }

    if (
      !areThereEmptyFields &&
      thereAreErrors.length === 0 &&
      Object.values(formValues?.installer_state).length > 0 &&
      Object.values(formValues?.installer_city).length > 0 &&
      Object.values(formValues?.installer_zipcode).length > 0
    ) {
      setError('');
    } else if (
      areThereEmptyFields ||
      thereAreErrors.length > 0 ||
      Object.values(formValues?.installer_state).length === 0 ||
      Object.values(formValues?.installer_city).length === 0 ||
      Object.values(formValues?.installer_zipcode).length === 0
    ) {
      setError('mandatoryFieldsMissing');
    }
  };

  const capitalizeFirstChar = (str: string) => {
    let first = str[0].toUpperCase();
    let rest = str.slice(1);
    return str === 'zip' ? 'Zip Code' : first.concat(rest);
  };

  const formatZipCodes = (zipCodes: any) => {
    return zipCodes.map((zpCode: any) => {
      const { zip } = zpCode;
      return {
        ...zpCode,
        value: zip,
        label: zip,
      };
    });
  };

  const formatLocations = (data: any) => {
    return data.map((item: any) => {
      const { name, isoCode } = item;
      return {
        ...item,
        label: name,
        value: isoCode ? isoCode : name,
      };
    });
  };

  const retrieveList = (field: string) => {
    switch (field) {
      case 'installer_state':
        return allStates;
      case 'installer_city':
        return allCities;
      case 'installer_zipcode':
        return allCityZipCodes;
      default:
        return [];
    }
  };

  const returnValue = (formField: any) => {
    return formField === `installer_state`
      ? formValues.installer_state?.name
      : formField === `installer_city`
      ? formValues.installer_city?.name
      : formField === `installer_zipcode`
      ? formValues.installer_zipcode?.value
      : ``;
  };

  const initAllStates = () => {
    let allStates: any = State.getStatesOfCountry(COUNTRY_CODE);
    allStates = formatLocations(allStates);

    setAllStates(allStates);

    const stateNames = allStates.map((elem: any) => elem.name);
    setDataArrs({ ...dataArrs, states: stateNames });

    return allStates;
  };

  const prepareFields = () => {
    initAllStates();
  };

  const grabSuggestion = (id: string, currentSuggestion: string) => {
    //mark which input is being selected, in order to handle the error display
    setCurrentField(id);
    setSuggests({ ...suggs, [id]: currentSuggestion });
  };

  const validateInputtedVals = (suggs: any) => {
    switch (currentField) {
      case 'installer_state':
        if (dataArrs?.states?.includes(suggs?.installer_state)) {
          setErrors({
            ...errors,
            installer_state: '',
          });
        } else {
          setErrors({
            ...errors,
            installer_state: 'Please select a state from the list',
          });
        }
        break;

      case 'installer_city':
        if (dataArrs?.cities?.includes(suggs?.installer_city)) {
          setErrors({
            ...errors,
            installer_city: '',
          });
        } else {
          setErrors({
            ...errors,
            installer_city: 'Please select a city from the list',
          });
        }
        break;
      case 'installer_zipcode':
        if (dataArrs?.zips?.includes(suggs?.installer_zipcode)) {
          setErrors({
            ...errors,
            installer_zipcode: '',
          });
        } else {
          setErrors({
            ...errors,
            installer_zipcode: 'Please select a zipcode from the list',
          });
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    saveNewInstaller({ ...newInstaller, code: compItem.code });
  }, [compItem.code]);

  useEffect(() => {
    const currentCompany: any = localStorage.getItem('@companyItem');
    let parsed: any;
    if (currentCompany) {
      parsed = JSON.parse(currentCompany);

      saveCompItemGlobally(parsed);
    }
  }, []);

  //clear data when user quits adding installer
  useEffect(() => {
    if (userCleared) {
      saveNewInstaller({
        name: '',
        email: '',
        phone: '',
        code: compItem?.code,
      });
      setFormValues(config.states.formValues);
      setErrors();
    }
  }, [userCleared]);

  useEffect(() => {
    validateEmptyFieldsOrErrors();
  }, [errors, newInstaller, formValues]);

  useEffect(() => {
    prepareFields();
  }, []);

  useEffect(() => {
    if (formValues?.installer_state?.label) {
      const { name, value } = formValues.installer_state;
      let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
      tempCities = formatLocations(tempCities);
      setAllCities([...tempCities]);

      //to posterior comparison - handle suggestion errs:
      const cityNames = tempCities.map((elem: any) => elem.name);

      setDataArrs({ ...dataArrs, cities: cityNames });
    }
  }, [formValues?.installer_state?.label]);

  useEffect(() => {
    if (formValues?.installer_city?.label) {
      const {
        name,
        stateCode,
        latitude,
        longitude,
      } = formValues.installer_city;
      let tempZipCodesByCoords: any = ZipCodes.lookupByCoords(
        latitude,
        longitude,
      );
      let tempZipCodes: any = ZipCodes.lookupByName(name, stateCode);
      tempZipCodes = formatZipCodes(
        tempZipCodes && tempZipCodes.length > 0
          ? tempZipCodes
          : [tempZipCodesByCoords],
      );

      setAllCityZipCodes([...tempZipCodes]);

      //to posterior comparison:
      const zipNums = tempZipCodes.map((elem: any) => elem.zip);

      setDataArrs({ ...dataArrs, zips: zipNums });
    }
  }, [formValues?.installer_city?.label]);

  useEffect(() => {
    //if the suggestion is empty , empty also the formvalue to display the error
    validateInputtedVals(suggs);
  }, [suggs]);

  return (
    <Template
      id="installers-add"
      title={config.strings.add.title}
      goBack={() => {
        history.push('/admin/dashboard/companies-list/company/installers');
      }}
    >
      <BreadcrumbsItem to={`/admin/dashboard/company/installers/add-edit`}>
        {config.strings.add.breadcrumbTitle}
      </BreadcrumbsItem>

      <div id="add-installer--page">
        <div className="jumbotron container-add min--h">
          <h4 className="centered">{config.strings.add.mainTitle} </h4>
          <p className="lead centered card-top-ft">
            {config.strings.add.pText}
          </p>
          <hr className="my-4" />
          <div className="container pdg">
            <div className="row d-flex justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1 left-side">
                {config.fields.map((elem: any, index: number) => {
                  if (elem.id === `telephone-input-spec1`) {
                    return (
                      <div
                        className="form-group form-group-edit-ins1"
                        key={index}
                      >
                        <Label className={`label-cmp-6`}>
                          {capitalizeFirstChar(elem.name)}
                          <span className="required-ast">*</span>
                        </Label>
                        <Phone
                          className={`${`form-group form-group-edit-ins1 the-phone-input`} ${
                            errors && errors[elem.name] ? `class-err-phone` : ``
                          }`}
                          label={elem}
                          disabled={false}
                          key={index}
                          value={newInstaller?.phone}
                          onChange={(
                            value: any,
                            country: any,
                            e: any,
                            formattedValue: any,
                          ) =>
                            handlePhoneChange(value, country, formattedValue)
                          }
                          name={elem.name}
                        />
                        <div
                          className="render-error"
                          style={{ marginTop: '5px' }}
                        >
                          {errors && errors[elem.name] ? errors[elem.name] : ''}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div
                      className="form-group form-group-edit-ins1"
                      key={index}
                    >
                      <Label className={`label-cmp-6`}>
                        {capitalizeFirstChar(elem.name)}
                        <span className="required-ast">*</span>
                      </Label>
                      <div className="input-group">
                        <Input
                          type={elem.type}
                          className={`${elem.className} ${
                            errors && errors[elem.name] ? `class-err` : ``
                          }`}
                          id={elem.id}
                          name={elem.name}
                          aria-describedby={elem.ariaDescribedBy}
                          placeholder={elem.placeholder}
                          children={elem.label}
                          value={returnVal(elem)}
                          onChange={getUserInput}
                        />
                      </div>
                      <div className="render-error">
                        {errors && errors[elem.name] ? errors[elem.name] : ''}
                      </div>
                    </div>
                  );
                })}
                <div className="form-group form-group-edit-ins1">
                  <Label className={`label-cmp-6`}>
                    Company Code
                    <TooltipView
                      title={
                        <GenTooltip
                          title={`Grab the Company ID`}
                          textOne={`Copy the Company ID to clipboard`}
                        />
                      }
                    >
                      <span>
                        <button
                          type="button"
                          id="create-clt-fareg-btn3"
                          onClick={() => copyToClipboard(compItem?.code)}
                        >
                          <FaRegCopy
                            style={{
                              color: '#000000',
                              strokeWidth: '1',
                              stroke: 'white',
                            }}
                            className="copy-to-clipboard-icon-clt fareg-i"
                          />
                        </button>
                      </span>
                    </TooltipView>
                  </Label>
                  <div className="input-group">
                    <Input
                      type={'text'}
                      className={'form-control'}
                      id={'installer-id2'}
                      name={'installer-id'}
                      aria-describedby={''}
                      placeholder={'Generated ID'}
                      value={compItem?.code || ''}
                      disabled={true}
                    />
                    <div className="input-group-append"></div>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1 right-side">
                {config.formConfig.map((formField: any, index: number) => {
                  return (
                    <div className={formField.className} key={index}>
                      <div className="input-group">
                        <label className={`label-cmp-6`}>
                          {formField.label}
                          <span className="required-ast">*</span>
                        </label>
                      </div>
                      {allStates.length > 0 && (
                        <div
                          key={autosuggestKey}
                          id="autosuggest--add--inst"
                          className={
                            errors &&
                            errors.hasOwnProperty(formField?.field) &&
                            errors[formField?.field].length > 0
                              ? 'auto-sugg-errclass'
                              : ''
                          }
                        >
                          <AutoSuggest
                            grabSuggestion={grabSuggestion}
                            dataWasCleared={userCleared}
                            id={formField.field}
                            handleChange={(newState: any) => {
                              setFormValues((prevState: any) => {
                                return {
                                  ...prevState,
                                  [formField.field]: { ...newState },
                                };
                              });
                            }}
                            data={retrieveList(formField.field)}
                            value={returnValue(formField.field)}
                            {...formField.fields}
                          />
                        </div>
                      )}
                      <div className="render-error">
                        {errors && errors[formField.field]}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1 left-side"></div>
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 the-form-col1 right-side">
                <Button
                  className="small-button dismiss-btn float-right-btn mr2"
                  onClick={() => {
                    cancel();
                  }}
                >
                  <span className="span--icon btn-child-ft">
                    <HiOutlineXCircle className="cancel" /> CANCEL{' '}
                  </span>
                </Button>

                <Button
                  className="small-button ok float-right-btn"
                  dataToggle={'modal'}
                  dataTarget={config.strings.add.dataTarget}
                  disabled={mandatoryFieldsMissing}
                  type="button"
                  onClick={() => {}}
                >
                  <span className="span--icon btn-child-ft">
                    <RiSave3Fill className="edit" /> SAVE{' '}
                  </span>
                </Button>
              </div>
            </div>

            {mandatoryFieldsMissing && (
              <div className="row d-flex justify-content-center">
                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-12 pdg alert--col1">
                  <div
                    className="alert alert-info mt-2 alert-w alert-pl"
                    role="alert"
                  >
                    <span className="required-ast">*</span>{' '}
                    {config.strings.warning}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`save-changes-to-new-installer`}
        title={config.modalStrings.edit}
        icon={<RiEdit2Line className="edit" />}
        body={config?.modalStrings?.aboutToUpdate}
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={config?.modalStrings?.sureToProceed}
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
        onClickCancel={() => {
          clearNewInstaller();
          setFormValues(config.states.formValues);

          resetKey('reset');

          setTimeout(() => {
            resetKey('init');
          }, 1);
        }}
        onClick={() => {
          saveInstaller();
        }}
        //handle modal automatic closing when
        //there is a real update going on:
        /* modalMustClose={modalMustClose}
        handleModalState={handleModalState}*/
        modalMustClose={false}
        handleModalState={() => {}}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminInstallersState, adminCompaniesState } = state;
  return {
    installerItem: adminInstallersState.installerItem,
    compItem: adminCompaniesState.compItem,
    newInstaller: adminInstallersState.newInstaller,
    mandatoryFieldsMissing: adminInstallersState.mandatoryFieldsMissing,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminInstallersState, adminCompaniesState } = dispatch;
  return {
    clearNewInstaller: adminInstallersState.clearNewInstaller,
    saveCompItemGlobally: adminCompaniesState.saveCompItemGlobally,
    setUserIsTyping: adminInstallersState.setUserIsTyping,
    saveNewInstaller: adminInstallersState.saveNewInstaller,
    setError: adminInstallersState.setError,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInstaller);

import { RelevantProps, Labels, CardStrings } from './interface';

export const relevantProps: RelevantProps = {
  user: ['email', 'addresses', 'phones', 'last_name', 'first_name'],
  device: [
    'isSystem',
    'device_type',
    'device_name',
    'details',
    'status',
    'device_city',
    'device_state',
    'device_zipcode',
  ],
  company: ['name', 'code', 'phone', 'email', 'state', 'city', 'zipcode'],
  sensor: ['name', 'mac', 'uuid', 'datapoint', 'health'],
};

export const labels: Labels = {
  user: {
    email: 'E-mail',
    addresses: 'Address',
    phones: 'Phone',
    last_name: 'Last name',
    first_name: 'First name',
  },
  device: {
    isSystem: 'System',
    device_type: 'Device type',
    device_name: 'Device name',
    details: 'Details',
    status: 'Status',
    device_city: 'Device city',
    device_state: 'Device state',
    device_zipcode: 'Device zipcode',
  },
  company: {
    name: 'Name',
    code: 'Code',
    city: 'City',
    state: 'State',
    zipcode: 'Zip code',
  },
  sensor: {
    name: 'Sensor name',
    mac: 'Mac Address',
    uuid: 'UUID',
    health: 'Health parameters',
    datapoint: 'Datapoints',
    local: 'Local sensor',
  },
};

export const strings: CardStrings = {
  userTitle: 'User details',
  devTitle: 'Device data',
  sensorTitle: 'Sensor data',
  companyTitle: 'Company data',
};

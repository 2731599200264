import React from 'react';
import moment from 'moment';
import './styles.scss';



interface NotificationComponentProps {
  account_id: string;
  alerts_id: string;
  content: any;
  message_id: string;
  system_id: string;
  status: any;
  sys_name?: string;
  createdDate?: any;
}

const NotificationComponent: React.FC<NotificationComponentProps> = ({
  content,
  sys_name,
  createdDate,
  ...props
}) => {
  const { title, msm }: any = content || {};



  return (
    <div id="notific--container" >
      <div className="notfy-item--title">
        <span>{title || ''}</span>
      </div>
      <div className="notfy-item--body">
        <span>{sys_name || ''}</span><br/>
        <span>{msm || ''}</span><br/>
        <span>
          {createdDate ? moment(new Date(createdDate)).format('MM-DD-YY hh:mm') : '. . .'}
        </span>
      </div>
    </div>
  );
};

export default NotificationComponent;

const handleImgErr = (event: any) => {
  event.target.src = '/avatar.png';
};

const isPhone = (key: string, value: any) => {
  if (
    key === 'phones' &&
    value &&
    Array.isArray(value) &&
    value.length > 0 &&
    value[0].hasOwnProperty('phone')
  ) {
    return true;
  } else return false;
};

const isAddress = (key: string, value: any) => {
  if (
    key === 'addresses' &&
    value &&
    value?.length > 0 &&
    value[0]?.address_line_one &&
    value[0]?.city &&
    value[0]?.zip_code &&
    value[0]?.state
  ) {
    return true;
  } else return false;
};

const handleImage = (item: any) => {
  if (item?.picture && item?.picture?.profile && item?.picture?.profile?.url) {
    return item?.picture?.profile?.url || '';
  }
  return item?.picture?.profile || '';
};

const handleRelevantProps = (item: any, relevantProps: any) => {
  return Object.keys(item)
    .filter((key: string) => relevantProps.includes(key))
    .reduce((obj: any, key: string) => {
      obj[key] = item[key];
      return obj;
    }, {});
};

const returnLimit = (resultType: string, item: any) => {
  switch (resultType) {
    case 'user':
      return Math.round(Object.entries(item).length / 2);

    case 'device':
      return 4;
    case 'sensor':
    case 'company':
      return Math.round(Object.entries(item).length / 2);

    default:
      return Object.entries(item).length / 2;
  }
};

const orderItemProperties = (item: any, resultType: string) => {
  if (resultType === 'user') {
    let modifiedUserItem = {
      first_name: item?.first_name,
      last_name: item?.last_name,
      email: item?.email,
      addresses: item?.addresses,
      phones: item?.phones,
    };

    return modifiedUserItem;
  }
  if (resultType === 'device') {
    let modifiedDev = {
      device_name: item?.device_name,
      device_type: item?.device_type,
      isSystem: item?.isSystem,
      status: item?.status,
      device_city: item?.device_city,
      device_state: item?.device_state,
      device_zipcode: item?.device_zipcode,
      details: item?.details,
    };

    return modifiedDev;
  }
  if (resultType === 'company') {
    let modifiedCompany = {
      name: item?.name,
      code: item?.code,
      city: item?.city,
      state: item?.state,
      zipcode: item?.zipcode,
    };

    return modifiedCompany;
  }

  if (resultType === 'sensor') {
    let modifiedSensor = {
      name: item?.name,
      uuid: item?.uuid,
      mac: item?.mac,
    };

    return modifiedSensor;
  }

  return {};
};

export const helpers = {
  handleImgErr: handleImgErr,
  isPhone: isPhone,
  isAddress: isAddress,
  handleImage: handleImage,
  handleRelevantProps: handleRelevantProps,
  returnLimit: returnLimit,
  orderItemProperties: orderItemProperties,
};

export default {
  prob: 'Probe',
  cdss: 'Device RSSI',
  hrss: 'Device RSSI',
  vpct: 'Ventilation Percentage Complete',
  g3id: 'AircyclerG3 device id',
  dmac: 'G3 Device Mac Address',
  uptm: 'Uptime',
  fmwv: 'Firmware version',
  pcbv: 'PCB Revision',
  usul: 'User Setting Update Local',
  thsa: 'Thermostat Call Active',
  thre: 'Thermostat Runtime Elapsed',
  oaqe: 'Outdoor Air Quality Event Control Flag',
  aqse: 'Air Quality Sensor Control Flag',
  uctr: 'User Control Flag',
  vcta: 'Ventilation Control Cycle Active',
  vpro: 'Ventilation Period Rollover',
  evrt: 'Exhaust Vent Required On Time',
  evrl: 'Exhaust Vent Required On Time Left',
  mhsv: 'Minute in Hour Start Exhaust Vent',
  cfrt: 'Central Fan Required On Time',
  cfrl: 'Central Fan Required On Time Left',
  mhsf: 'Minute in Hour Start Central Fan',
  mplf: 'Minutes in Period Left',
  errc: 'Error count',
  //   conf: 'Configuration',
  fmwu: 'Firmware url',
  fmwm: 'Firmware md5',
  lfdt: 'Last foreground time',
  crdt: 'Current_date_time',
  ccmd: 'Connected Device Command',
  resl: 'Result',
  smth: 'Supply Method',
  fffr: 'Fan Only Flow Rate CFM',
  fhfr: 'Fan Heat Flow Rate CFM',
  fcfr: 'Fan Cool Flow Rate CFM',
  exct: 'Exhaust Control Target',
  fcsp: 'Fan Connect Switch Present',
  fcst: 'Fan Connect Switch State',
  fcds: 'Fan Connect Delay State',
  fcdr: 'Fan Connect Delay Minutes Remaining',
  effr: 'Exhaust Fan Flow Rate (fan connect)',
  efdt: 'Exhaust Fan Delay Time (fan connect)',
  mner: 'Mins./ Hour Exhaust Run (fan conn.)',
  exwm: 'Exhaust Worker Mode (fan connect)',
  carq: 'Constant Air Required CFM',
  mixt: 'Mix Time',
  hroa: 'Hours of Operation Active',
  hrsv: 'Hour Start Ventilation',
  hstv: 'Hour Stop Ventilation',
  dmst: 'Damper Status',
  cfst: 'Central Fan Relay Status',
  tpmn: 'Temperature Probe Minimum',
  tpmx: 'Temperature Probe Maximum',
  ctmp: 'Current Temperature Reading',
  crhr: 'Current Relative Humidity Reading',
  ddia: 'Air Duct Diameter',
  cflw: 'Current Flow Reading',
  mnsf: 'Minutes per Hour Supply Flow',
  lcap: 'Last Conn. Access Point',
  tmzn: 'Current Set Timezone',
  prmd: 'Pair Mode',
  pmto: 'Pair Mode Timeout',
  blto: 'Bluetooth LE Timeout',
  gail: 'G3 Device Alias',
  excm: 'Exhaust Relay Set Command',
  cfcm: 'Central Fan Relay Set Command',
  oacm: 'Outside Air Damper Relay Set Command',
  fdrs: 'Factory Default Reset',
  cdvc: 'Connected Device Count',
  cdid: 'Connected Device ID',
  cail: 'Connected Device Alias',
  cidx: 'Conn. Device Index Pos On MQTT Broker',
  cpri: 'Conn. Device Display Priority',
  cdst: 'Connected Device State',
  vcct: 'Vent Connect Control Target Flag',
  vcfr: 'Exhaust Fan Flow Rate (vent conn.)',
  vcdt: 'Exhaust Fan Delay Time (vent conn.)',
  mvcr: 'Mins./Hour Exhaust Run (vent conn.)',
  vcwm: 'Exhaust Worker Mode (vent connect)',
  slst: 'Switch Status Light State',
  fcon: 'Force On',
  frte: 'Fan runtime elapsed',
  pcst: 'Probe Conn. Status Probe Status',
  dfps: 'Differential Pressure',
  stmp: 'Sensor Temperature',
  faep: 'Forward Active Energy Pulses',
  raep: 'Reverse Active Energy Pulses',
  aaep: 'Absolute Active Energy Pulses',
  frep: 'Forward Reactive Energy Pulses',
  rrep: 'Reverse Reactive Energy Pulses',
  arep: 'Absolute Reactive Energy Pulses',
  estt: 'Energy Read Status',
  irms: 'RMS Current',
  vrms: 'RMS Voltage',
  mnap: 'Mean Active Power',
  mnrp: 'Mean Reactive Power',
  vfrq: 'Voltage Frequency',
  pfct: 'Power Factor',
  phas: 'Phase Angle',
  mapp: 'Mean AP Power',
  prun: 'Probe run',
  evot: 'Fan Conn. (wired exhaust) Time (mins)',
  mxrh: 'Probe Maximum Relative Humidity',
  man_ctrl: 'Manual Control',
  cdvs: 'Connected Devices',
  watt: 'Wattage',
  cvoc: 'Current voc',
  cco2: 'Current co2',
  cp25: 'Current pm2.5',
  aqst: 'Current Temperature',
  aqsh: 'Current Humidity',
  slbr: '...',
};

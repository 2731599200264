import React, { useState, useEffect, Fragment } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Notification } from 'business/modules/admin/common';
import { RxMagnifyingGlass } from 'react-icons/rx';
import './styles.scss';

interface SearchProps {
  defSearchTerm: Function;
  searchTermInput: string;
  searchByTerm: Function;
  loading: any;
}

const Search: React.FC<SearchProps> = ({
  defSearchTerm,
  searchTermInput,
  searchByTerm,
  loading,
}) => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');

  const onChangeValue = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const searchByEnterKey = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      performSearch(event);
    }
  };

  const performSearch = async (event: any) => {
    let trimmedTerm: string = searchTerm.trim();

    if (trimmedTerm.length === 0) {
      //warn the user - no empty searches will be performed

      Notification({
        title: 'Warning',
        message: config?.notifications?.noEmptyFields?.error,
        type: 'warning',
      });
      return;
    }

    loading.start('Loading Results List...');

    await searchByTerm(trimmedTerm);

    history.push(`/admin/dashboard/search-results`);

    loading.stop();
  };

  useEffect(() => {
    defSearchTerm(searchTerm);
  }, [searchTerm]);

  return (
    <Fragment>
      <div
        className="input-group flex-nowrap search-bar admin-search--bar"
        id="search-box-container"
      >
        <div className="input-group-prepend">
          <span className="input-group-text" id="addon-wrapping">
            <RxMagnifyingGlass id="mag-glass-icon" />
          </span>
        </div>
        <input
          type="text"
          id="search-user-input-001-1"
          className="form-control search-user"
          placeholder="Insert your search criteria"
          aria-label="Username"
          aria-describedby="addon-wrapping"
          value={searchTermInput}
          onChange={onChangeValue}
          onKeyPress={event => searchByEnterKey(event)}
        />

        <div className="input-group-append">
          <button
            className="btn btn-secondary"
            type="button"
            id="search-input-btn"
            onClick={event => performSearch(event)}
          >
            {config?.strings?.search}
          </button>
        </div>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state: any) {
  const { searchState } = state;

  return {
    searchTermInput: searchState.searchTermInput,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, searchState } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    defSearchTerm: searchState.defSearchTerm,
    searchByTerm: searchState.searchByTerm,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Search);

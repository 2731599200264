import React from 'react';
import { PhoneInputProps } from './interface';
import { InputPhone } from '../../../../../react-components';
import './styles.scss';

//todo: replace all the internal common Phone components
const Telephone: React.FC<PhoneInputProps> = ({
  index,
  name,
  configInputs,
  errors,
  handleChange,
  handleChangeError,
  value,
  disabled,
  className,
}) => {
  return (
    <div
      className={`input--phone--field--common          
        ${disabled ? `telephone-disabled-style` : ''}`}
    >
      <InputPhone
        key={`phone--${index}`}
        handleChange={handleChange}
        handleChangeError={handleChangeError}
        name={name}
        value={value}
        error={errors}
        constraint={configInputs}
        disabled={disabled}
        classname={className ? className : ''}
      />
    </div>
  );
};

export default Telephone;

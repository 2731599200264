import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import './styles.scss';

const AdminAuthenticatedApp: React.FC = ({ children }) => {
  return (
    <div id="app-admin-session-wrapper">
      <Header />
      <div className="container-full">{children}</div>
      <Footer />
    </div>
  );
};

export default AdminAuthenticatedApp;

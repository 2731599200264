import React from 'react';
import { AirCyclerLogoPNG } from '../../../../assets/logos';
import { useHistory } from 'react-router-dom';

const Header: React.FC = () => {
  const history = useHistory();
  const path = window.location.pathname;

  return (
    <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4  bg-white border-bottom box-shadow pt-4 pb-4 justify-content-between">
      <img
        src={AirCyclerLogoPNG}
        alt="logo"
        height={'88px'}
        className={'cursor-pointer'}
        onClick={() => history.push('/')}
      />
      <div></div>
    </div>
  );
};

export default Header;

import React from 'react';
import './styles.scss';

interface CardProps {
  handleError: any;
  title: string;
  content: any;
  img: any;
}

const Card: React.FC<CardProps> = ({ img, handleError, title, content }) => {
  return (
    <div className="card">
      {img}
      <div className="card-body plpr0">
        <h5 className="card-title"> {title}</h5>

        <div className="container pdg">
          <div className="row">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;

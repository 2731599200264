import _ from 'lodash';
import { ConnectionBroker } from '../../core';
import configs from '../../business/config/config';

const SOCKET_URL: string = configs?.apis?.g3iot?.baseSocketURLs[1];


export const alerts = {
  state: {
    isConnected: false,
    connectionId: null,
    sockets: [],
    currentNotifications: [],
    originalAlerts: [],
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
    setConnectionState(state: any, payload: any) {
      return { ...state, isConnected: payload };
    },
    addSocket(state: any, payload: any){
      const { sockets } = state || {};
      let socketsClone: any = _.cloneDeep(sockets);
      socketsClone.push(payload);

      return { ...state, sockets: socketsClone };
    },
    updateNotifications(state: any, payload: any){
      return { ...state, currentNotifications: payload };
    },
  },
  effects: (dispatch: any) => ({
    async setAlertsConnection(data: any, rootState: any){
      const self: any = this;
      const { type, apiKey, userId } = data || {};
      const skUrl = `${SOCKET_URL}?x-iot-key=${apiKey}&type=${type}`;
      let socket: any = new WebSocket(skUrl);

      socket.addEventListener('message', (event: any) => {
        // console.log(
        //   '\n\n    [[ALERTS SOCKET]]    __________    MESSAGE   __________________   ',
        //   event,
        //   '\n',
        //   event?.data,
        //   '\n',
        // );
        if(event?.data){
          const receivedData: any = JSON.parse(event.data);
          const { type, data }: any = receivedData || {};

          if(type && type === 'connection'){
            const { alerts_id } = data || {};

            if(alerts_id){
              console.log(
                '\n\n      [ALERTS SOCKET]         Socket Connected...     ',
                alerts_id
              );
              self.updateState(true, 'isConnected');
              self.updateState(alerts_id, 'connectionId');
            }
          }else{
            const { content } = receivedData || {};
            // console.log(
            //   '\n\n      [ALERTS SOCKET]   .............    CONTENT MESSAGE     ',
            //   receivedData,
            //   '\n',
            //   content,
            //   '\n',
            // );
            if(content) self.updateNotifications(receivedData);
          }
        }
      });

      socket.addEventListener('open', (event: any) => {
        console.log('\n\n   ____________________________   [[ALERTS SOCKET]]       Socket Opening...');
        self.setConnectionState(true);
        socket.send(
          JSON.stringify({
            action: 'subscribeAlerts',
            eventType: 'SUBSCIBE-ALERTS',
            account: userId,
          })
        );
      });

      self.addSocket(socket);

      socket.addEventListener('close', (event: any) => {
        if (event?.reason === 'Going away') {
          console.log(
            '\n\n      [ALERTS SOCKET]       [Socket Closed] -> Reconnecting socket...'
          );
          self.setAlertsConnection(data);
        }
      });

      return socket;
    },
    async resetSockets(data: any){

    },
    async showActiveAlertsNotifications(data: any, rootState: any){
      const self: any = this;
      const { system_id, device_id, device_name }: any = data || {};
      const alertsResp: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'userAlerts.show',
        null,
        { system_id: system_id || device_id },
      );

      if(
        alertsResp.statusCode === 200 &&
        alertsResp.body
      ){
        const mappedAlerts: any = alertsResp.body.map((item: any) => {
          return {
            ...item,
            sys_id: system_id || device_id,
            sys_name: device_name,
          };
        });

        self.updateState(alertsResp.body, 'originalAlerts');
        self.updateState(mappedAlerts, 'currentNotifications');

        return mappedAlerts;
      }

      return;
    },
    async markNotificationsAsRead(data: any, rootState: any){
      const { alert_id, system_id } = data || {};
      let payload: any = { alert_id, system_id };

      const response: any = await ConnectionBroker.fetchAPI(
        'jitBE',
        'userAlerts.updateStatus',
        payload,
        {},
      );

      return response;
    },

    reset(payload: any, rootState: any) {
      const self: any = this;

      self.updateState(false, 'isConnected');
      self.updateState(null, 'connectionId');
      self.updateState([], 'sockets');
      self.updateState([], 'originalAlerts');
      self.updateState([], 'currentNotifications');
    },


  })
};

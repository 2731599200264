const isOb = (item: any) => {
  if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
    return true;
  } else return false;
};

const isArray = (item: any) => {
  return Array.isArray(item) ? true : false;
};

const handleRelevantProps = (item: any, relevantProps: any) => {
  return Object.keys(item)
    .filter((key: string) => relevantProps.includes(key))
    .reduce((obj: any, key: string) => {
      obj[key] = item[key];

      return obj;
    }, {});
};

const renderRow = (key: string) => {
  return key === 'station' ||
    key === 'info' ||
    key === 'fwUpgradeStatus' ||
    key === 'firmwareVersions' ||
    key === 'data' ||
    key === 'sensorsStatus' ||
    key === 'deviceEnvironment'
    ? true
    : false;
};

const truncVal = (val: number) => {
  return Math.trunc(val);
};

const handleInternalObject = (ob: any, key: string) => {
  if (key === 'deviceEnvironment') {
    return Object.values(ob).length > 0 ? Object.values(ob)[0] : ob;
  } else {
    return ob;
  }
};

export const helpers = {
  isOb: isOb,
  isArray: isArray,
  renderRow: renderRow,
  handleRelevantProps: handleRelevantProps,
  truncVal: truncVal,
  handleInternalObject: handleInternalObject,
};

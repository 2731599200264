import React from 'react';
import { ViewUserDetailsProps } from './interface';
import { ListGroup, ListContainer } from './components';
import { keys } from './config';
import { functions } from './helpers';

const ViewUserDetails: React.FC<ViewUserDetailsProps> = ({
  id,
  config,
  item,
}) => {
  const classes: any = {
    class1: `container-info pdg`,
    class2: `row`,
    role: ``,
    class3: `modal-content pdg`,
  };

  const filterItemObject = (clientItem: any) => {
    let filteredObject: any = {};

    if (Object.keys(clientItem).length > 0) {
      let filteredPropsArray = Object.entries(clientItem).filter(
        (item: any, val: any) => {
          return (
            item[0] !== 'details' &&
            item[0] !== '_id' &&
            item[0] !== 'updatedAt' &&
            item[0] !== '__v' &&
            item[0] !== 'redirect_url'
          );
        },
      );
      filteredPropsArray.forEach((item: any) => {
        filteredObject[item[0]] = item[1];
      });
    }
    return filteredObject;
  };

  return (
    <ListContainer config={config} id={id}>
      <ListGroup
        keys={keys}
        item={filterItemObject(item)}
        returnIcon={functions.returnIcon}
        returnKey={functions.returnKey}
        returnValue={functions.returnValue}
        classes={classes}
      />
    </ListContainer>
  );
};

export default ViewUserDetails;

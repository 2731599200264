import Err from '../error';
import APIs from '../../../business/services/apis';
import ConnectionRequest from '../connection-request';




interface FetchAPIRequestType {
  api: string;
  endpoint: string;
  data: any;
}

interface FetchAPIResponseType {
  result:boolean;
  body:any;
  statusCode:number
}

class ConnectionBroker {
  APIs: any;
  ADMIN_TOKEN_LABEL: string = '@aircyler:admin-token';


  constructor() {
    this.APIs = APIs;
  }

  async fetchAPI(api: any, endpoint: string, data?: any, params?: any): Promise<FetchAPIResponseType> {
    return new Promise(async (resolve, reject) => {
      const token: any = localStorage.getItem('@aircyler:token');

      const APIendpoint = endpoint.split('.');
      const requestModule = APIendpoint[0];
      const requestEndpoint = APIendpoint[1];
      const requestAPI = this.APIs[api];
      let requestCounter = 0;

      const resultHandler = () => {
        if (requestCounter < this.APIs[api].basesURL.baseURLs.length - 2) {
          requestCounter++;
          nextRequest();
        } else {
          requestCounter = 0;
          // nextRequestMock();
        }
      };

      const nextRequest = () => {
        const options: any = {
          baseURL: requestAPI.basesURL.baseURLs[requestCounter],
          method: requestAPI[requestModule][requestEndpoint].method,
          url: requestAPI[requestModule][requestEndpoint].path,
          data: data,
          timeout: (30 * 1000),
          headers: {
            authorization: `Bearer ${token}`,
          },
          responseType: 'json',
          maxContentLength: -1,
          maxBodyLength: -1,
        };

        if(params){
          options.params = { ...params };
        }

        ConnectionRequest.call(options)
          .then((res: any) => {
               resolve(res?.data);
          })
          .catch((err: any) => {
            const errs = `${err}`;

            if (err.response?.status || errs.indexOf('timeout') > 0) {
              Err(
                err?.response?.data,
                err.response?.status,
                requestAPI[requestModule][requestEndpoint].path,
              );
                resolve({ statusCode: 400, result:false, body:null });
            } else {
              resultHandler();
            }
          });
      };

      nextRequest();
    });

  };

  async fetchFoobotAPI(api: any, endpoint: string, body?: any, params?: any, data?: any): Promise<FetchAPIResponseType> {
    return new Promise(async (resolve, reject) => {
      const { apikey } = data;
      const token: any = localStorage.getItem('@aircyler:token');

      const APIendpoint = endpoint.split('.');
      const requestModule = APIendpoint[0];
      const requestEndpoint = APIendpoint[1];
      const requestAPI = this.APIs[api];

      let requestCounter = 0;
      let pathUrl: string = requestAPI[requestModule][requestEndpoint].path;

      const options: any = {
        baseURL: requestAPI.basesURL.baseURLs[requestCounter],
        method: requestAPI[requestModule][requestEndpoint].method,
        url: pathUrl,
        data: body,
        timeout: (30 * 1000),
        headers: {
          authorization: `Bearer ${token}`,
          'x-key': apikey
        },
        responseType: 'json',
        maxContentLength: -1,
        maxBodyLength: -1,
      };

      if(params){
        options.params = { ...params };
      }

      ConnectionRequest.call(options)
        .then((res: any) => {
             resolve(res?.data);
        })
        .catch((err: any) => {
          const errs = `${err}`;
          if (err.response?.status || errs.indexOf('timeout') > 0) {
            Err(err?.response?.data, err.response?.status);
              resolve({ statusCode: 400, result:false, body:null });
          } else {
            resolve({ statusCode: 400, result:false, body:null });
          }
        });

    });
  };

  async fetchG3IotAPI(
    api: any,
    endpoint: string,
    apiKey: string,
    data?: any,
    params?: any
  ): Promise<FetchAPIResponseType> {
    return new Promise(async (resolve, reject) => {
      // const token: any = localStorage.getItem('@aircyler:token');

      const APIendpoint = endpoint.split('.');
      const requestModule = APIendpoint[0];
      const requestEndpoint = APIendpoint[1];
      const requestAPI = this.APIs[api];
      let requestCounter = 0;

      const resultHandler = () => {
        if (requestCounter < this.APIs[api].basesURL.baseURLs.length - 2) {
          requestCounter++;
          nextRequest();
        } else {
          requestCounter = 0;
          // nextRequestMock();
        }
      };

      const nextRequest = () => {
        let options: any = {
          baseURL: requestAPI.basesURL.baseURLs[requestCounter],
          method: requestAPI[requestModule][requestEndpoint].method,
          url: requestAPI[requestModule][requestEndpoint].path,
          timeout: (30 * 1000),
          headers: {
            'x-iot-key': apiKey,
          },
          responseType: 'json',
          maxContentLength: -1,
          maxBodyLength: -1,
        };

        if(params){
          options.params = { ...params };
        }

        if(data !== null && data !== undefined){
          options.data = data;
        }


        ConnectionRequest.call(options)
          .then((res: any) => {
               resolve(res?.data);
          })
          .catch((err: any) => {
            const errs = `${err}`;
            if (err.response?.status || errs.indexOf('timeout') > 0) {
              Err(err?.response?.data, err.response?.status);
                resolve({ statusCode: 400, result:false, body:null });
            } else {
              resultHandler();
            }
          });
      };

      nextRequest();
    });

  };



  async fetchAdminAPI(api: any, endpoint: string, data?: any, params?: any): Promise<FetchAPIResponseType> {

    return new Promise(async (resolve, reject) => {

      const token: any = localStorage.getItem(this.ADMIN_TOKEN_LABEL);

      const APIendpoint = endpoint.split('.');
      const requestModule = APIendpoint[0];
      const requestEndpoint = APIendpoint[1];
      const requestAPI = this.APIs[api];
      let requestCounter = 0;

      const resultHandler = () => {
        if (requestCounter < this.APIs[api].basesURL.baseURLs.length - 2) {
          requestCounter++;
          nextRequest();
        } else {
          requestCounter = 0;
          // nextRequestMock();
        }
      };

      const nextRequest = () => {
        const options: any = {
          baseURL: requestAPI.basesURL.baseURLs[requestCounter],
          method: requestAPI[requestModule][requestEndpoint].method,
          url: requestAPI[requestModule][requestEndpoint].path,
          data: data,
          timeout: (30 * 1000),
          headers: {
            authorization: `Bearer ${token}`,
          },
          responseType: 'json',
          maxContentLength: -1,
          maxBodyLength: -1,
        };

        if(params){
          options.params = { ...params };
        }

        ConnectionRequest.call(options)
          .then((res: any) => {
               resolve(res?.data);
          })
          .catch((err: any) => {
            const errs = `${err}`;
            if (err.response?.status || errs.indexOf('timeout') > 0) {
              Err(err?.response?.data, err.response?.status);
                resolve({ statusCode: 400, result:false, body:null });
            } else {
               resultHandler();
            }
          });
      };

      nextRequest();
    });

  };


}

export default new ConnectionBroker();

import { Labels } from './interface';

export const labels: Labels = {
  name: 'Name',
  code: 'Code',
  phone: 'Phone',
  email: 'E-mail',
  state: 'State',
  city: 'City',
  zipcode: 'Zip Code',
  status: 'Status',
  first_name: 'First name',
  last_name: 'Last name',
  address_line_one: 'Address',
  address_line_two: 'Address (cont.)',
  zip_code: 'Zip Code',
  picture: 'Picture',
  device_name: 'Device name',
  device_type: 'Device type',
  isSystem: 'System',
  device_city: 'Device city',
  device_state: 'Device State',
  device_zipcode: 'Device Zip Code',
  details: 'Details',
  device_id: 'Device ID',
  uuid: 'UUID',
  mac: 'MAC',
  datapoint: 'Datapoint',
  airQuality: 'Air quality',
  health: 'Health',
  time: 'Time',
  data: 'Data',
  value: 'Value',
  sensor: 'Sensor',
  unit: 'Unit',
  start: 'Start',
  end: 'End',
  sensors: 'Sensors',
  units: 'Units',
  datapoints: 'Datapoints',
  station: 'Station',
  distanceToDeviceKm: 'Distance to device (km)',
  info: 'Info',
  connectivity: 'Connectivity',
  fwUpgradeStatus: 'Firmware Upgrade Status',
  sensorsStatus: "Sensors 'status",
  deviceEnvironment: 'Device environment',
  country: 'Country',
  lat: 'Latitude',
  lon: 'Longitude',
  latitude: 'Latitude',
  longitude: 'Longitude',
  provider: 'Provider',
  deviceType: 'Device type',
  modelVersion: 'Model version',
  isUpToDate: 'Up to date',
  firmwareVersions: 'Fmw versions',
  isInTestFirmwareGroup: 'Test Firmware group',
  lastFirmwareUpgradeDate: 'Last fmw upgrade',
  otaLoop: 'Ota loop',
  sensorsStats: "Sensors' stats",
  nbHwFailures7days: 'Failures 7 days',
  nbEmptySensorsValue7days: 'Empty values 7 days',
  nearestOutdoorStation: 'Nearest outdoor station',
  updated: 'Updated',
  providerId: 'Provider ID',
  distanceToLocationInKm: 'Distance to location (km)',
  id: 'ID',
  firmwareType: 'Firmware type',
  version: 'Version',
  latest: 'Latest',
  co2: 'CO2',
  hum: 'Humidity',
  pm: 'PM2.5',
  tmp: 'Temperature',
  voc: 'VOC',
  min: 'Minimum',
  max: 'Maximum',
  mean: 'Mean',
  p90: 'P90',
  count: 'Count',
  p10: 'P10',
  local: 'Local',
  battery: 'Battery',
  zip: 'Zip Code',
  product_code: 'Product code',
  settings: 'Settings',
  //settings keys:
  aqse: 'Air Quality Sensor Control Flag',
  ccmd: 'Connected Device Command',
  oacm: 'Outside Air Damper Relay Set Command',
  cfcm: 'Central Fan Relay Set Command',
  cidx: 'Connected Device Index Pos On MQTT Broker',
  cdst: 'Connected Device State',
  excm: 'Exhaust Relay Set Command',
  oaqe: 'Outdoor Air Quality Event Control Flag',
  g3id: 'AircyclerG3 device id',
  dmac: 'G3 Device Mac Addresses',
  uptm: 'Uptime',
  fmwv: 'Firmware version',
  pcbv: 'PCB Revision',
  vpct: 'Ventilation Percentage Complete',
  thsa: 'Thermostat Call Active',
  uctr: 'User Control Flag',
  vcta: 'Ventilation Control Cycle Active',
  evrt: 'Exhaust Vent Required On Time',
  evrl: 'Exhaust Vent Required On Time Left',
  mhsv: 'Minute in Hour Start Exhaust Vent',
  cfrt: 'Central Fan Required On Time',
  cfrl: 'Central Fan Required On Time Left',
  mhsf: 'Minute in Hour Start Central Fan',
  mplf: 'Minutes in Period Left',
  evot: 'Fan Conn. (wired exhaust) On Time (minutes)',
  errc: 'Error count',
  conf: 'Configuration',
  fmwu: 'Firmware url',
  fmwm: 'Firmware md5',
  lfdt: 'Last foreground time',
  crdt: 'Current Date Time',
  resl: 'Result',
  smth: 'Supply Method',
  fffr: 'Fan Only Flow Rate CFM',
  fhfr: 'Fan Heat Flow Rate CFM',
  fcfr: 'Fan Cool Flow Rate CFM',
  exct: 'Exhaust Control Target',
  fcsp: 'Fan Connect Switch Present',
  fcst: 'Fan Connect Switch State',
  fcds: 'Fan Connect Delay State',
  fcdr: 'Fan Connect Delay Min. Remaining',
  effr: 'Exhaust Fan Flow Rate (fan conn.)',
  efdt: 'Exhaust Fan Delay Time (fan conn.)',
  mner: 'Min./Hour Exhaust Run (fan conn.)',
  exwm: 'Exhaust Worker Mode (fan conn.)',
  carq: 'Constant Air Required CFM',
  mixt: 'Mix Time',
  hroa: 'Hours of Operation Active',
  hrsv: 'Hour Start Ventilation',
  hstv: 'Hour Stop Ventilation',
  dmst: 'Damper Status',
  cfst: 'Central Fan Relay Status',
  tpmn: 'Temperature Probe Minimum',
  tpmx: 'Temperature Probe Maximum',
  ctmp: 'Current Temperature Reading',
  mxrh: 'Probe Maximum Relative Humidity',
  crhr: 'Current Relative Humidity Reading',
  ddia: 'Air Duct Diameter',
  cflw: 'Current Flow Reading',
  mnsf: 'Minutes per Hour Supply Flow',
  lcap: 'Last Connected Access Point SSID',
  tmzn: 'Current Set Timezone',
  prmd: 'Pair Mode',
  pmto: 'Pair Mode Timeout',
  blto: 'Bluetooth LE Timeout',
  gail: 'G3 Device Alias',
  fdrs: 'Factory Default Reset',
  cdvc: 'Connected Device Count',
  cdvs: 'Connected Devices',
  cdid: 'Connected Device ID',
  cail: 'Connected Device Alias',
  cpri: 'Connected Device Display Priority',
  vcct: 'Vent Connect Control Target Flag',
  vcfr: 'Exhaust Fan Flow Rate (vent connect)',
  vcdt: 'Exhaust Fan Delay Time (vent connect)',
  mvcr: 'Mins./ Hour Exhaust Run (vent conn.)',
  vcwm: 'Exhaust Worker Mode (vent connect)',
  slst: 'Switch Status Light State',
  fcon: 'Force On',
  frte: 'Fan runtime elapsed',
  pcst: 'Probe Connection Status',
  none: 'Probe Status',
  dfps: 'Differential Pressure',
  stmp: 'Sensor Temperature',
  faep: 'Forward Active Energy Pulses',
  raep: 'Reverse Active Energy Pulses',
  aaep: 'Absolute Active Energy Pulses',
  frep: 'Forward Reactive Energy Pulses',
  rrep: 'Reverse Reactive Energy Pulses',
  arep: 'Absolute Reactive Energy Pulses',
  estt: 'Energy Read Status',
  irms: 'RMS Current',
  vrms: 'RMS Voltage',
  mnap: 'Mean Active Power',
  mnrp: 'Mean Reactive Power',
  vfrq: 'Voltage Frequency',
  pfct: 'Power Factor',
  phas: 'Phase Angle',
  mapp: 'Mean AP Power',
  prun: 'Probe run',
  rssi: 'RSSI',
  vpro: 'Ventilation Period Rollover',
  usul: 'User Setting Update Local',
  mp25: 'mp25',
  cp25: 'Current pm 2.5',
  cvoc: 'Current voc',
  thre: 'Thermostat Runtime Elapsed',
  lusr: 'lusr',
  hrss: 'RSSI',
  cmpy: 'cmpy',
  prob: 'Probe',
  inst: 'inst',
  mco2: 'mco2',
  mvoc: 'mvoc',
  cco2: 'Current CO2',
  aqsh: 'Current humidity ',
  cdss: 'Device RSSI',
  slbr: '...',
  aqst: 'Current temperature',
  cfl2: 'Probe2 Curr. Flow Read.',
  pro2: 'Probe2 Status',
  crh2: 'Probe2 Curr. Relative Hum. Read.',
  pcs2: 'Probe2 Conn. Status',
  ctm2: 'Probe2 Curr. Temp. Read.',
  lsnm: 'lsnm',
  wiaq: 'wiaq',
};

export const relevantProps: string[] = [
  'name',
  'latitude',
  'longitude',
  'zip',
  'city',
  'state',
  'country',
  'settings',
];

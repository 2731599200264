import React, { useEffect, useState, Fragment } from 'react';
import config from './config';
import validate from 'validate.js';
import { connect } from 'react-redux';
import { CreateCompanyProps } from './interface';
import { functions } from './helpers';
import { useHistory } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { State, City } from 'country-state-city';
import ZipCodes from 'zipcodes';
import { FaRegCopy } from 'react-icons/fa';
import { GrCircleQuestion } from 'react-icons/gr';
import {
  FormFooter,
  PhoneInput,
  GenerateID,
  IdInput,
  NoPhoneNorCode,
} from '../../common';
import {
  Modal,
  Notification,
  TooltipView,
  GenTooltip,
  Template,
  Label,
  AutoSuggest,
} from 'business/modules/admin/common';
import './styles.scss';

const COUNTRY_CODE = 'US';

const CreateCompany: React.FC<CreateCompanyProps> = ({
  getTheCompaniesList,
  setCompanyData,
  getCompanyGeneratedCode,
  newCompany,
  setError,
  clearNewCompany,
  companyData,
  saveNewCompany,
  userCleared,
  generatedCode,
  handleModalState,
  modalMustClose,
  mandatoryFieldsMissing,
  createANewCompany,
  loading,
}) => {
  const history = useHistory();
  const [phone, setPhone] = useState('');
  const [errors, setErrors]: any = useState(null);
  const [currentField, setCurrentField] = useState('');
  const [formValues, setFormValues] = useState<any>(config.states.formValues);
  const [allStates, setAllStates]: any = useState([]);
  const [allCities, setAllCities]: any = useState([]);
  const [allCityZipCodes, setAllCityZipCodes]: any = useState([]);
  const [dataArrs, setDataArrs] = useState<any>({
    states: [],
    cities: [],
    zips: [],
  });
  const [suggs, setSuggests] = useState<any>({
    device_name: '',
    device_city: '',
    device_zipcode: '',
  });

  const _labels: any = {
    'company-id': 'Company ID',
  };

  const copyContent = async (copyTxtToClip: any) => {
    try {
      await navigator.clipboard.writeText(copyTxtToClip);
    } catch (err) {
      //handle error copying to clipboard
    }
  };

  const copyToClipboard = (code: any, field: any) => {
    copyContent(code);

    Notification({
      title: 'Success',
      message: `${_labels[field.name]} copied to clipboard.`,
      type: 'success',
    });
  };

  const handleCreateInput = async (event: any) => {
    let name: any = event.target.name;

    let currentFields: any = { [name]: event.target.value };

    let currentConstraints: any = {
      [name]: {
        ...config.constraints[name as keyof typeof config.constraints],
      },
    };

    setCompanyData({
      ...companyData,
      [event.target.name]: event.target.value,
      phone: phone,
    });

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: '' };
        });
      })
      .catch((err: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: err[name] };
        });
      });
  };

  const handlePhoneCreate = async (value: any) => {
    let currentFields: any = { phone: value };
    let currentConstraints: any = {
      phone: {
        ...config.constraints['phone' as keyof typeof config.constraints],
      },
    };
    setPhone(value);

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: '' };
        });
      })
      .catch((err: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, phone: err.phone };
        });
      });
  };

  const validateEmptyFieldsOrErrors = () => {
    let areThereEmptyFields: any;
    areThereEmptyFields = functions.preventEmptyData(newCompany);

    let thereAreErrors: any = [];

    if (errors) {
      thereAreErrors = Object.values(errors).filter(
        (item: any) => item.length > 0,
      );
    }

    if (
      !areThereEmptyFields &&
      thereAreErrors.length === 0 &&
      !areFieldsEmpty(formValues)
    ) {
      setError('');
    } else if (
      areThereEmptyFields ||
      thereAreErrors.length > 0 ||
      areFieldsEmpty(formValues)
    ) {
      setError('mandatoryFieldsMissing');
    }
  };

  const areFieldsEmpty = (formValues: any) => {
    if (
      Object.values(formValues?.device_state).length === 0 ||
      Object.values(formValues?.device_city).length === 0 ||
      Object.values(formValues?.device_zipcode).length === 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const generateNewCode = async () => {
    let newCode: any = await getCompanyGeneratedCode();

    if (newCode?.statusCode === 200) {
      setCompanyData({ ...companyData, code: newCode?.body?.data?.code });
    } else {
      //TODO handle code gen error

      return;
    }
  };

  const createCompany = async () => {
    const newCompanyData: any = {
      name: newCompany?.name?.trim(),
      code: newCompany?.code,
      phone: newCompany?.phone,
      email: newCompany?.email?.trim(),
      state: formValues?.device_state?.name,
      city: formValues?.device_city?.name,
      zipcode: formValues?.device_zipcode?.value,
    };

    loading.start('Creating company...');

    let createCompany: any = await createANewCompany(newCompanyData);

    clearNewCompany();

    if (createCompany?.statusCode === 200 && createCompany?.result) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.create?.success,
        type: 'success',
      });
    } else {
      Notification({
        title: 'Error!',
        message: config?.notifications?.create?.error,
        type: 'error',
      });
    }

    clearNewCompany();

    loading.stop();

    await getTheCompaniesList();
  };

  const formatZipCodes = (zipCodes: any) => {
    return zipCodes.map((zpCode: any) => {
      const { zip } = zpCode;
      return {
        ...zpCode,
        value: zip,
        label: zip,
      };
    });
  };

  const formatLocations = (data: any) => {
    return data.map((item: any) => {
      const { name, isoCode } = item;
      return {
        ...item,
        label: name,
        value: isoCode ? isoCode : name,
      };
    });
  };

  const initAllStates = () => {
    let allStates: any = State.getStatesOfCountry(COUNTRY_CODE);
    allStates = formatLocations(allStates);
    setAllStates(allStates);

    const stateNames = allStates.map((elem: any) => elem.name);
    setDataArrs({ ...dataArrs, states: stateNames });

    return allStates;
  };

  const prepareFields = () => {
    initAllStates();
  };

  const retrieveList = (field: string) => {
    switch (field) {
      case 'device_state':
        return allStates;
      case 'device_city':
        return allCities;
      case 'device_zipcode':
        return allCityZipCodes;
      default:
        return [];
    }
  };

  const grabSuggestion = (id: string, currentSuggestion: string) => {
    //mark which input is being selected, in order to handle the error display
    setCurrentField(id);
    setSuggests({ ...suggs, [id]: currentSuggestion });
  };

  const validateInputtedVals = (suggs: any) => {
    switch (currentField) {
      case 'device_state':
        if (dataArrs?.states?.includes(suggs?.device_state)) {
          setErrors({
            ...errors,
            device_state: '',
          });
        } else {
          setErrors({
            ...errors,
            device_state: 'Please select a state from the list',
          });
        }
        break;

      case 'device_city':
        if (dataArrs?.cities?.includes(suggs?.device_city)) {
          setErrors({
            ...errors,
            device_city: '',
          });
        } else {
          setErrors({
            ...errors,
            device_city: 'Please select a city from the list',
          });
        }
        break;
      case 'device_zipcode':
        if (dataArrs?.zips?.includes(suggs?.device_zipcode)) {
          setErrors({
            ...errors,
            device_zipcode: '',
          });
        } else {
          setErrors({
            ...errors,
            device_zipcode: 'Please select a zipcode from the list',
          });
        }
        break;

      default:
        break;
    }
  };

  const returnValue = (formField: any) => {
    return formField === `state`
      ? formValues?.device_state?.name
      : formField === `city`
      ? formValues?.device_city?.name
      : formField === `zip_code`
      ? formValues?.device_zipcode?.value
      : ``;
  };

  useEffect(() => {
    const getTheCode = async () => {
      let code: any = await getCompanyGeneratedCode();

      if (code?.statusCode !== 200) {
        setError('mandatoryFieldsMissing');

        Notification({
          title: 'Error!',
          message: `An error occurred on generating code. Please, click on Generate ID button`,
          type: 'error',
        });
      } else {
        setError('');
      }
    };

    getTheCode();
  }, []);

  useEffect(() => {
    saveNewCompany({ ...companyData, phone: phone, code: generatedCode });
  }, [companyData, phone, generatedCode]);

  useEffect(() => {
    if (userCleared) {
      setCompanyData({
        name: '',
        email: '',
        phone: '',
        code: generatedCode,
      });

      setFormValues(config.states.formValues);

      setPhone('');
      setErrors();
    }
  }, [userCleared]);

  useEffect(() => {
    validateEmptyFieldsOrErrors();
  }, [newCompany, generatedCode, formValues, errors]);

  useEffect(() => {
    prepareFields();
  }, []);

  useEffect(() => {
    if (formValues?.device_state?.label) {
      const { name, value } = formValues.device_state;
      let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
      tempCities = formatLocations(tempCities);
      setAllCities([...tempCities]);

      //to posterior comparison - handle suggestion errs:
      const cityNames = tempCities.map((elem: any) => elem.name);

      setDataArrs({ ...dataArrs, cities: cityNames });
    }
  }, [formValues?.device_state?.label]);

  useEffect(() => {
    if (formValues?.device_city?.label) {
      const { name, stateCode, latitude, longitude } = formValues.device_city;
      let tempZipCodesByCoords: any = ZipCodes.lookupByCoords(
        latitude,
        longitude,
      );
      let tempZipCodes: any = ZipCodes.lookupByName(name, stateCode);
      tempZipCodes = formatZipCodes(
        tempZipCodes && tempZipCodes.length > 0
          ? tempZipCodes
          : [tempZipCodesByCoords],
      );

      setAllCityZipCodes([...tempZipCodes]);

      //to posterior comparison:
      const zipNums = tempZipCodes.map((elem: any) => elem.zip);

      setDataArrs({ ...dataArrs, zips: zipNums });
    }
  }, [formValues?.device_city?.label]);

  useEffect(() => {
    //if the suggestion is empty , empty also the formvalue to display the error
    validateInputtedVals(suggs);
  }, [suggs]);

  return (
    <Template
      title={config?.companyCreate?.title}
      id="dashboard-create-company"
      goBack={() => {
        history.push('/admin/dashboard/companies-list');
      }}
    >
      <BreadcrumbsItem to={config?.companyCreate?.breadcrumbItem}>
        {config.strings.breadcrumbCreate}
      </BreadcrumbsItem>

      <div
        className={config.companyCreate.class}
        id="create-new-company--screen"
      >
        <h4 className="centered">{config.companyCreate.mainTitle} </h4>
        <p className="lead centered card-top-ft">
          {config.companyCreate.pText}
        </p>
        <hr className="my-4" />
        <div className="container id-create-company--form--011">
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5 the-form-col1 left-side">
              {config.fields.map((field: any, index: number) => {
                if (field.isPhone) {
                  return (
                    <div className="form-group id-input-elem" key={index}>
                      <Label className={`label-cmp-6`}>
                        {field?.label} <span className="required-ast">*</span>
                      </Label>
                      <div className="input-group">
                        <PhoneInput
                          key={index}
                          value={phone}
                          index={index}
                          handleChange={(value: any) =>
                            handlePhoneCreate(value)
                          }
                          handleChangeError={() => {}}
                          name={field.name}
                          configInputs={
                            config.constraints[
                              field.name as keyof typeof config.constraints
                            ]
                          }
                          errors={errors ? errors[field.name] : ''}
                          editMode={``}
                        />
                      </div>
                    </div>
                  );
                }
                if (field.isCode) {
                  return (
                    <div className="form-group id-input-elem" key={index}>
                      <Label className={`label-cmp-6`}>
                        {field.label}{' '}
                        <TooltipView
                          title={
                            <GenTooltip
                              title={config?.strings?.tooltipTitle}
                              textOne={config?.strings?.tooltipTxt}
                            />
                          }
                        >
                          <span className="hover--tooltip">
                            <GrCircleQuestion className="click-here-tooltip" />
                          </span>
                        </TooltipView>
                        <TooltipView
                          title={
                            <GenTooltip
                              title={`Grab the ${_labels[field?.name]}`}
                              textOne={`Copy the ${
                                _labels[field?.name]
                              } to clipboard`}
                            />
                          }
                        >
                          <span>
                            <button
                              type="button"
                              id="create-clt-fareg-btn1"
                              onClick={() =>
                                copyToClipboard(generatedCode, field)
                              }
                            >
                              <FaRegCopy
                                style={{
                                  color: '#000000',
                                  strokeWidth: '1',
                                  stroke: 'white',
                                }}
                                className="copy-to-clipboard-icon-clt fareg-i"
                              />
                            </button>
                          </span>
                        </TooltipView>
                      </Label>

                      <div className="input-group">
                        <IdInput
                          index={index}
                          elem={field}
                          generatedCode={generatedCode}
                          newCodeGenerator={
                            <GenerateID
                              onClick={generateNewCode}
                              disabledGenerate={false}
                              label={config.genLabel[`create`]}
                            />
                          }
                        />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <Fragment key={field.id}>
                      <NoPhoneNorCode
                        className={`label-cmp-6`}
                        required={<span className="required-ast">*</span>}
                        field={field}
                        index={index}
                        elem={field}
                        data={companyData}
                        getUserInput={(event: any) => handleCreateInput(event)}
                        err={errors ? errors[field.name] : ''}
                      />
                    </Fragment>
                  );
                }
              })}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5  the-form-col1 right-side">
              {config.formConfig.map((formField: any, index: number) => {
                return (
                  <div className={formField.className} key={index}>
                    <div className="input-group">
                      <label className={`label-cmp-6`}>
                        {formField.label}{' '}
                        <span className="required-ast">*</span>
                      </label>
                    </div>
                    {allStates.length > 0 && (
                      <div
                        className={
                          errors &&
                          errors.hasOwnProperty(formField?.field) &&
                          errors[formField?.field].length > 0
                            ? 'auto-sugg-errclass'
                            : ''
                        }
                      >
                        <AutoSuggest
                          grabSuggestion={grabSuggestion}
                          dataWasCleared={userCleared}
                          id={formField.field}
                          handleChange={(newState: any) => {
                            setFormValues((prevState: any) => {
                              return {
                                ...prevState,
                                [formField.field]: { ...newState },
                              };
                            });
                          }}
                          data={retrieveList(formField.field)}
                          value={returnValue(formField.field)}
                          {...formField.fields}
                        />
                      </div>
                    )}
                    <span className="render-error">
                      {errors && errors[formField.field]}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12 col-sm-12 col-lg-5 col-xl-5 the-form-col1 left-side"></div>

            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-5 col-xl-5 the-form-col1 right-side">
              <FormFooter
                rowClass="row action-btns"
                className={`col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pdg`}
                dataTarget={`#save-company`}
                emptyFields={false}
                onClick={clearNewCompany}
                onClickValidate={() => validateEmptyFieldsOrErrors()}
                disabled={mandatoryFieldsMissing}
              />
            </div>
          </div>

          {mandatoryFieldsMissing && (
            <div className="row d-flex justify-content-center">
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-12 pdg alert--col1">
                <div
                  className="alert alert-info mt-2 alert-w alert-pl"
                  role="alert"
                >
                  <span className="required-ast">*</span>{' '}
                  {config.strings.warning}
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal
          config={config}
          bodyClass={functions.handleOpts(`create__comp`, 'bodyClass')}
          className={`small-button ok`}
          onClick={() => {
            createCompany();
          }}
          onClickCancel={clearNewCompany}
          title={functions.handleOpts(`create__comp`, `title`)}
          id={`save-company`}
          icon={functions.handleOpts(`create__comp`, `icon`)}
          body={functions.handleOpts(`create__comp`, 'bodies')}
          modalMustClose={modalMustClose}
          handleModalState={handleModalState}
          label={config.labels[`create__comp` as keyof typeof config.labels]}
          hasConfirmBtn={true}
          sureToProceed={config.modalStrings.sureToProceed}
        />
      </div>
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminCompaniesState } = state;

  return {
    generatedCode: adminCompaniesState.generatedCode,
    companyData: adminCompaniesState.companyData,
    mandatoryFieldsMissing: adminCompaniesState.mandatoryFieldsMissing,
    modalMustClose: adminCompaniesState.modalMustClose,
    newCompany: adminCompaniesState.newCompany,
    userCleared: adminCompaniesState.userCleared,
    currentCompanyToEdit: adminCompaniesState.currentCompanyToEdit,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminCompaniesState, loader } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    clearNewCompany: adminCompaniesState.clearNewCompany,
    setError: adminCompaniesState.setError,
    getCompanyGeneratedCode: adminCompaniesState.getCompanyGeneratedCode,
    setCompanyData: adminCompaniesState.setCompanyData,
    saveNewCompany: adminCompaniesState.saveNewCompany,
    createANewCompany: adminCompaniesState.createANewCompany,
    handleModalState: adminCompaniesState.handleModalState,
    getTheCompaniesList: adminCompaniesState.getTheCompaniesList,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCompany);
